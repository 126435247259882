import Header from "components/Header";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import useFetchKyc from "hooks/useFetchKyc";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { encrypt, decrypt } from "services/decrypt";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";
import ConfirmationModal from "components/ConfirmationModal";
import { dateUtil } from "utils/dateutil";

const Register = () => {
    const location = useLocation();
    const { customerId, kycData: kycDataFromContext } = useDataContext();
    const { updateErrorData } = useNotifContext();
    const { data: kycData, isPending } = useFetchKyc(customerId ? customerId : localStorage.getItem("customerId"));
    const [vrtlAcctNos, setVrtlAcctNos] = useState([]);
    const [selectedVan, setSelectedVan] = useState(null);
    const [nextStep, setNextStep] = useState(null);
    const navigate = useNavigate();
    const vanRef = useRef(null);

    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalConfirmText, setModalConfirmText] = useState(null);
    const [modalGoto, setModalGoto] = useState(null);
    
    const handleChange = (event) => {
        const selectedVanLocal = JSON.parse(event.target.value);
        const selectedVanLocalIndex = vanRef.current.selectedIndex;

        setNextStep(null);
        setSelectedVan(null);
        kycDataFromContext.selectedVrtlAcctNo = null;
        kycDataFromContext.selectedIndex = null;

        setTimeout(() => {
            // NOTE: Check if selected VAN has existing Policy
            const quoteIdLocal = selectedVanLocal?.quoteId ?? null;
            const policyIdLocal = selectedVanLocal?.quoteId ?? null;
            const ecIdLocal = selectedVanLocal?.ecId ?? null;
            const eligibleForRenewalLocal = selectedVanLocal?.eligibleForRenewal ?? null;
            const expiryDate = new Date(selectedVanLocal?.expDate);
            const dateNow = dateUtil.dateNow();

            const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
            if (policyIdLocal === null && ecIdLocal === null || dateNow > expiryDate) {
                // if( dateNow > expiryDate ) {
                // NOTE: if quoteID and ecID is null can create policy
                logger.log(location.pathname, "Selected VAN", JSON.parse(event.target.value));
                kycDataFromContext.selectedVrtlAcctNo = selectedVanLocal;
                kycDataFromContext.selectedIndex = selectedVanLocalIndex;

                setSelectedVan(selectedVanLocal);
                setNextStep("/purchase/review/?customerId=" + customerIdParams);
                // }
               
            } else {
                if (eligibleForRenewalLocal) {
                    // NOTE: if quoteID and ecID is present means it has existing policy but is eligible for renewal can renew.
                    logger.log(location.pathname, "Selected VAN", JSON.parse(event.target.value));
                    kycDataFromContext.selectedVrtlAcctNo = selectedVanLocal;
                    kycDataFromContext.selectedIndex = selectedVanLocalIndex;
                    setSelectedVan(selectedVanLocal);
                    // updateErrorData({
                    //     showModal: true,
                    //     modalType: "success",
                    //     modalMessage: "You already have Existing Policy Plan and is Eligible to Renew",
                    //     buttonText: "Continue Renewal",
                    //     buttonExit: true,
                    //     redirectUrl: `/purchase/review/?customerId=${customerIdParams}`,
                    // });
                    setConfirmationMessage("You already have Existing Policy Plan and is Eligible to Renew");
                    setModalConfirmText("Continue Renewal");
                    setModalOpen(true);
                    setModalGoto(`/purchase/review/?customerId=${customerIdParams}`);
                } else {
                    // NOTE: if not Policy already exists redirect to policy management page
                    const params = btoa(encrypt({ quoteId: quoteIdLocal, ecId: ecIdLocal }));
                    localStorage.setItem("params", params);
                    logger.log(location.pathname, "LocalStorage set params", { quoteId: quoteIdLocal, ecId: ecIdLocal });
                    // updateErrorData({
                    //     showModal: true,
                    //     modalType: "success",
                    //     modalMessage: "You already have Existing Policy Plan.",
                    //     buttonText: "Go to Policy Management",
                    //     buttonExit: true,
                    //     redirectUrl: `/management/?params=${params}&customerId=${customerIdParams}`,
                    // });
                    setConfirmationMessage("You already have an existing policy plan.");
                    setModalConfirmText("Go to Policy Management");
                    setModalOpen(true);
                    setModalGoto(`/management/?params=${params}&customerId=${customerIdParams}`);
                }
            }
        }, 500);
    };

    const verifySelection = () => {
        if (nextStep !== null && selectedVan?.vrtlAcctNo !== "Choose VAN") {
            // note: insert the kycData to the localStorage to be accessed in case of page refresh
            localStorage.setItem("kycData", encrypt(kycData));
            logger.log(location.pathname, "localStorage kycData set.", null);
            navigate(nextStep);
        } else {
            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: "Please select your GCash Virtual Account Number (VAN)",
                redirectUrl: null,
            });
        }
    };

    const handleConfirm = (linkTo) => {
        setModalOpen(false);
        navigate(linkTo);
    };

    const handleCancel = () => {
        setModalOpen(false);
        vanRef.current.value = JSON.stringify({ vrtlAcctNo: "Choose VAN", policyId: undefined, quoteId: undefined });
    };

    useEffect(() => {
        const customerIdLocal = customerId !== null ? customerId : localStorage.getItem("customerId");
        if (customerIdLocal === null) {
            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: "Something went wrong.",
                redirectUrl: "goback",
            });
        }

        if (!isPending && kycData) {
            let vrtl = [{ vrtlAcctNo: "Choose VAN", policyId: undefined, quoteId: undefined }, ...kycData?.vrtlAcctNos];
            setVrtlAcctNos(vrtl);
            setTimeout(() => {
                if (vanRef.current !== null) {
                    logger.log(location.pathname, "select focused.", null);
                    vanRef.current.disabled = false;
                    vanRef.current.focus();
                }
                // restore the selected option
                if (vanRef.current.selectedIndex !== undefined) {
                    const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
                    vanRef.current.selectedIndex = kycData?.selectedIndex || 0;
                    setNextStep("/purchase/review/?customerId=" + customerIdParams);
                }
            }, 1000);
        }
    }, [isPending]);

    return (
        <div>
            {kycData && (
                <div>
                    <div className="flex flex-col h-screen">
                        <Navigator title={"Renew"} useRouterHistory={true} />
                        <Header currentStep="0" />

                        <div className="px-5 py-5 h-auto mb-auto">
                            <div>
                                <p className="text-md opacity-90 mb-2">GCash Virtual Account Number (VAN)</p>
                                {vrtlAcctNos.length > 0 && (
                                    <select className="select" onChange={handleChange} ref={vanRef} disabled>
                                        {vrtlAcctNos.length &&
                                            vrtlAcctNos.map((van, index) => {
                                                return (
                                                    <option value={JSON.stringify(van)} key={van?.vrtlAcctNo + 1}>
                                                        {van.vrtlAcctNo}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                )}
                            </div>
                        </div>
                        <div className="bg-slate-100 p-5 border-t">
                            <div className="mb-8">
                                <p className="text-sm text-center leading-relaxed opacity-90">Please select an active GCash Virtual Account Number to proceed.</p>
                            </div>
                            <button type="button" className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" disabled={!nextStep} onClick={() => verifySelection()}>
                                Next
                            </button>
                        </div>
                    </div>

                    <ConfirmationModal
                        showModal={modalOpen}
                        modalMessage={confirmationMessage}
                        onConfirm={() => {
                            handleConfirm(modalGoto);
                        }}
                        onCancel={handleCancel}
                        modalType={"info_success"}
                        confirmBtnText={modalConfirmText}
                        cancelBtn={false}
                    />
                </div>
            )}
            {isPending && <Skeleton />}
        </div>
    );
};

export default Register;
