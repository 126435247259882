const Skeleton = () => {
  return (
    <div>
      <div className="flex flex-col h-screen animate-pulse">
        <div className="h-36 bg-slate-200 mb-5"></div>

        <div className="py-5 px-5 mb-auto">
          <div className="grid grid-col gap-5 mb-5">
            <div className="h-6 w-1/2 bg-slate-200"></div>
            <div className="h-6 w-3/4 bg-slate-200"></div>
          </div>

          <div className="grid grid-col gap-5 mb-5">
            <div className="h-6 w-1/2 bg-slate-200"></div>
            <div className="h-6 w-full bg-slate-200"></div>
          </div>

          <div className="grid grid-col gap-5 mb-5">
            <div className="h-6 w-1/2 bg-slate-200"></div>
            <div className="h-6 w-3/4 bg-slate-200"></div>
          </div>
          <div className="grid grid-col gap-5 mb-5">
            <div className="h-6 w-1/2 bg-slate-200"></div>
            <div className="h-6 w-full bg-slate-200"></div>
          </div>
        </div>

        <div className="h-36 bg-slate-200 mt-5"></div>
      </div>
    </div>
  );
};

export default Skeleton;
