import Header from "components/Header";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import useSetNextDueDate from "hooks/useSetNextDueDate";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";
import { numberUtil } from "utils/numberutil";
import { encrypt, decrypt, restoreCryptoKey } from "services/decrypt";

const Summary = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { updateErrorData } = useNotifContext();
    const { kycData, customerId, selectedPlanData, quotationData, updateSelectedPlanData } = useDataContext();
    const [frequency, setFrequency] = useState(null);
    const { data: nextDueDate, isPending } = useSetNextDueDate(frequency);

    const scrollToMe = useRef();
    const [autoRenewTag, setAutoRenewTag] = useState(false);

    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    const restoreSelectedPlan = async () => {
        await restoreCryptoKey();
        const selectedPlanDataLocal = selectedPlanData !== null ? selectedPlanData : JSON.parse(decrypt(localStorage.getItem("selectedPlanData")));
        if (selectedPlanDataLocal && kycData) {
            logger.log(location.pathname, "selected plan", selectedPlanDataLocal);
            setAutoRenewTag(quotationData[0]?.productInfo?.autoRenewTag);
            // setFrequency(selectedPlanDataLocal?.freqUnit);
            setFrequency({
                expDate: kycData.selectedVrtlAcctNo?.policyId || kycData.selectedVrtlAcctNo?.quoteId !== null ? kycData.selectedVrtlAcctNo?.expDate : null,
                freqUnit: selectedPlanDataLocal?.freqUnit,
            });
            updateSelectedPlanData(selectedPlanDataLocal);
        } else {
            updateErrorData({
                showModal: true,
                modalType: "error",
                modalMessage: "Something went wrong.",
                redirectUrl: "/intro/?customerId=" + localStorage.getItem("customerId"),
            });
        }
    };

    useEffect(() => {
        scrollToMe.current.scrollIntoView({
            behavior: "instant",
            block: "start",
        });
        restoreSelectedPlan();
    }, []);

    return (
        <div>
            <div ref={scrollToMe}></div>
            {selectedPlanData && (
                <div className="flex flex-col h-screen">
                    <div className="h-auto mb-auto" style={{ overflow: "auto" }}>
                        <Navigator title={"Summary"} useRouterHistory={true} />
                        <Header currentStep="3" />

                        <div>
                            <div className="px-5 py-5 border-b border-gray-300">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="text-lg font-bold">Card Protect</p>
                                    </div>
                                    <div className="place-self-center">
                                        <button className="text-md font-bold text-blue-600 hover:underline" onClick={() => nextStep("/purchase/select_plan")}>
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="px-5 pt-5">
                            <div>
                                <p className="text-md opacity-90 mb-2">Protection Plan</p>
                                <p className="text-lg font-bold">{selectedPlanData.planName}</p>
                            </div>
                        </div> */}

                            <div className="px-5 pt-5">
                                <div>
                                    <p className="text-md opacity-90 mb-2">Protection Period</p>
                                    <p className="text-lg font-bold">{selectedPlanData && selectedPlanData?.freqDesc}</p>
                                </div>
                            </div>

                            <div className="px-5 pt-5">
                                <div>
                                    <p className="text-md opacity-90 mb-2">Total Aggregate Benefit Amount</p>
                                    <p className="text-lg font-bold">PHP {selectedPlanData && numberUtil.formatNumber(selectedPlanData?.maxCov)}</p>
                                </div>
                            </div>

                            <div className="px-5 pt-5">
                                <div>
                                    <p className="text-md opacity-90 mb-2">Premium</p>
                                    <p className="text-lg font-bold">
                                        {selectedPlanData && selectedPlanData?.freqUnit === "year" && `PHP ${selectedPlanData.totPrem} / year`}
                                        {selectedPlanData && selectedPlanData?.freqUnit === "month" && `PHP ${selectedPlanData.totPrem} / month`}
                                        {selectedPlanData && selectedPlanData?.freqUnit === "semi-annum" && `PHP ${selectedPlanData.totPrem} / 6 months`}
                                    </p>
                                </div>
                            </div>

                            <div className="px-5 pt-5 mb-5">
                                <div>
                                    <p className="text-md opacity-90 mb-2">Next Due Date</p>
                                    <p className="text-lg font-bold">{selectedPlanData && selectedPlanData && nextDueDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-slate-100 p-6 border-t">
                        <div className="mb-5 px-2">
                            <p className="text-sm text-center opacity-90 leading-relaxed mb-5">
                                By clicking this button, I agree to share my personal information to BPI MS for the processing of my application and managing my policy.
                            </p>
                            {autoRenewTag && (
                                <p className="text-sm text-center opacity-90 leading-relaxed">
                                    I understand that my GCash wallet will be automatically deducted for succeeding premiums as part of my enrollment on auto-renewal basis.
                                </p>
                            )}
                        </div>
                        <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => nextStep("/purchase/coverage")}>
                            Next
                        </button>
                    </div>
                </div>
            )}
            {!selectedPlanData && <Skeleton />}
        </div>
    );
};

export default Summary;
