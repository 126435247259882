const dateUtil = {
    dateNow: () => {
        let date = new Date();
        return date;
    },

    formatDateToReadable: (dateString) => {
        const parsedDate = new Date(dateString);
        const formattedDate = parsedDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
        });

        return formattedDate;
    },

    formatDateToReadableLong: (dateString) => {
        const parsedDate = new Date(dateString);
        const formattedDate = parsedDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        return formattedDate;
    },

    formatDateToReadableWithTime1: (dateString) => {
        // const options = { year: "numeric", month: "long", day: "2-digit", hour: "2-digit", minute: "2-digit" };
        const parsedDate = new Date(dateString);
        const formattedDate = parsedDate.toLocaleDateString("en-PH", { year: "numeric", month: "long", day: "2-digit" });
        const formattedTime = parsedDate.toLocaleTimeString("en-PH", { hour: "2-digit", minute: "2-digit" });
        return `${formattedDate} ${formattedTime}`;
    },

    formatDateToReadableWithTime: (dateString) => {
        const parsedDate = new Date(dateString);

        // Create an object to map months
        const months = {
            0: "January",
            1: "February",
            2: "March",
            3: "April",
            4: "May",
            5: "June",
            6: "July",
            7: "August",
            8: "September",
            9: "October",
            10: "November",
            11: "December",
        };

        const month = months[parsedDate.getUTCMonth()];
        const day = parsedDate.getUTCDate();
        const year = parsedDate.getUTCFullYear();
        let hour = parsedDate.getUTCHours();
        let minute = parsedDate.getUTCMinutes();
        const ampm = hour >= 12 ? "PM" : "AM";
        if (hour > 12) {
            hour -= 12;
        }
        hour = hour.toString().padStart(2, "0");
        minute = minute.toString().padStart(2, "0");
        const formattedDate = `${month} ${day}, ${year} ${hour}:${minute} ${ampm}`;

        return formattedDate;
    },

    formatDateToTimeOnly: (dateString) => {
        const parsedDate = new Date(dateString);

        let hour = parsedDate.getUTCHours();
        let minute = parsedDate.getUTCMinutes();
        const ampm = hour >= 12 ? "PM" : "AM";
        if (hour > 12) {
            hour -= 12;
        }

        hour = hour.toString().padStart(2, "0");
        minute = minute.toString().padStart(2, "0");
        const formattedTime = `${hour}:${minute} ${ampm}`;
        return formattedTime;
    },
};

export { dateUtil };
