import Navigator from "components/Navigator";

const MakeAClaim = () => {
    return (
        <div className="bg-slate-100 h-screen">
            <Navigator title={"Make a Claim"} useRouterHistory={true} />

            <div className="p-6 text-blue-950 bg-white mb-5">
                <div className="text-lg font-bold mt-5 mb-5">Contact Us</div>
                <div className="mb-5 font-bold text-md opacity-90 leading-relaxed">
                    Call the BPI MS Personal Cyber Insurance Hotline at (632) 8840-9444 to file a claim.
                </div>
            </div>

            <div className="p-6">
                <a
                    href="tel: 632-8840-9444"
                    className="flex flex-grid justify-center  max-w-lg p-6 text-white rounded-full shadow-md bg-blue-700 hover:bg-blue-800"
                >
                    <p className="text-white font-bold">Call the Hotline</p>
                </a>
            </div>
        </div>
    );
};

export default MakeAClaim;
