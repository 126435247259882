import arrow_back from "assets/images/arrow_back.png";
import { useNavigate } from "react-router-dom";

const Navigator = ({ title = "", useRouterHistory = false, ifBacked }) => {
    const navigate = useNavigate();

    const goBack = (params) => {
        if (params) {
            navigate(-1);
        } else {
            navigate(ifBacked);
        }
    };

    return (
        <div className="sticky top-0 z-50">
            <div className="bg-blue-600 py-5 px-5 drop-shadow-md relative">
                <div className="place-self-auto left-6 bottom-5 absolute pr-5">
                    <div className="hover:cursor-pointer hover:bg-gray-800 rounded-full">
                        <img src={arrow_back} alt="logo" width={"30px"} onClick={() => goBack(useRouterHistory)} />
                    </div>
                </div>
                <div className="flex justify-between">
                    <div className="place-self-center w-full">
                        <h1 className="font-bold text-white text-center text-lg w-auto">{title === "" ? "GCash x BPI MS" : title}</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navigator;
