import { useEffect, useState } from "react";
import { encrypt, decrypt, restoreCryptoKey } from "services/decrypt";
import { decryptKyc } from "services/kyc-get";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";

const useFetchKyc = (customerId) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { kycData, updateKyc, updateQuotationData } = useDataContext();
    const { updateErrorData, updateVanErrorData } = useNotifContext();

    async function fetchKyc() {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        await decryptKyc(customerId)
            .then((response) => {
                if (response?.length) {
                    if (response[0].status === "SUCCESS") {
                        logger.log("useFetchKyc", "kyc/get response", response[1]);
                        // save kycData in the localstorage as intact for value comparison later in the review.js to check if the form is edited or not
                        localStorage.setItem("kycDataIntact", encrypt(response[1]?.kycInputs));
                        updateKyc(response[1]);
                        // NOTE: Set customerInfo
                        // updateQuotationData({ customerInfo: response[1] });
                        // NOTE: Set customerId
                        updateQuotationData([{ customerId: customerIdParams }]);
                        if (!response[1].vrtlAcctNos.length) {
                            throw new Error("van_error");
                        } else {
                            // NOTE: append item in the response for testing
                            // response[1].vrtlAcctNos.push(
                            //     {
                            //         policyId: null,
                            //         quoteId: null,
                            //         ecId: null,
                            //         vrtlAcctNo: "123456787070",
                            //         eligibleForRenewal: null,
                            //     },
                            //     {
                            //         policyId: null,
                            //         quoteId: null,
                            //         ecId: null,
                            //         vrtlAcctNo: "123456787171",
                            //         eligibleForRenewal: null,
                            //     },
                            //     {
                            //         policyId: null,
                            //         quoteId: null,
                            //         ecId: null,
                            //         vrtlAcctNo: "123456787122",
                            //         eligibleForRenewal: null,
                            //     }
                            // );

                            setData(response[1]);
                            setIsPending(false);
                        }
                    } else {
                        logger.log("useFetchKyc", "Error", response);
                        throw new Error(response[0]?.status || "Something went wrong");
                    }
                } else {
                    throw new Error("Something went wrong");
                }
            })
            .catch((error) => {
                logger.log("useFetchKyc", "Catched Error", error);
                if (error.message === "van_error") {
                    updateVanErrorData({
                        showModal: true,
                        modalType: "info",
                        modalMessage: "Something went wrong.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                } else if (error.message === "TIMEOUT REACHED") {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Application Timeout.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                } else {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Something went wrong.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                }
                setIsPending(false);
            });
    }

    async function checkKycData(customerId) {
        // NOTE: Check if a kycData is existing in the localStorage.
        const kycDataLS = localStorage.getItem("kycData") !== null ? JSON.parse(decrypt(localStorage.getItem("kycData"))) : "";
        const kycDataLocal = kycData || kycDataLS;

        if (kycDataLocal && kycDataLocal?.vrtlAcctNos !== undefined && kycDataLocal.vrtlAcctNos.length) {
            setTimeout(() => {
                updateKyc(kycDataLocal);
                setData(kycDataLocal);
                setIsPending(false);
            }, 500);
            localStorage.setItem("kycDataIntact", encrypt(kycData));
        } else {
            await restoreCryptoKey();
            if (customerId) fetchKyc();
        }
    }

    useEffect(() => {
        checkKycData(customerId);
    }, [customerId]);

    return { data, isPending };
};

export default useFetchKyc;
