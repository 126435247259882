// import bannercircle from "assets/images/banner-circle.png";
import policypageicon from "assets/images/icons/Policy-page-icon.png";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import ViewBreakdown from "components/ViewBreakdown";
import useFetchPlanData from "hooks/useFetchPlanData";
import useFetchPolicyDetails from "hooks/useFetchPolicyDetails";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import { decrypt } from "services/decrypt";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import { dateUtil } from "utils/dateutil";
import logger from "utils/logger";
import { numberUtil } from "utils/numberutil";
import { tabs } from "utils/variables/managementVar";
import { reactSlickVar } from "utils/variables/reactSlickVar";
import Activity from "./Activity";
import Overview from "./Overview";
import Services from "./Services";

const Index = () => {
    const { sharedData, customerId, updateSharedData, updateSelectedPlanData, selectedPlanData, policyDetailsData } = useDataContext();
    const refs = useRef([]);
    const scrollToMe = useRef();
    const slider = useRef();
    const location = useLocation();
    const [oldTab, setOldTab] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [policyGetPayload, setPolicyGetPayload] = useState(null);
    const [showModalState, setShowModalState] = useState(false);
    const [showModalStateVb, setShowModalStateVb] = useState(false);
    const { updateErrorData } = useNotifContext();

    const { data: structuredPlanDataFetched, isPending } = useFetchPlanData();
    const { data: policyDetailsDataFetched, isPending: isPendingPolicyDetails } = useFetchPolicyDetails(policyGetPayload);

    reactSlickVar.dots = false;
    reactSlickVar.adaptiveHeight = true;
    let settings = {
        ...reactSlickVar,
        beforeChange: (current, next) => {
            if (oldTab != null) {
                refs.current[oldTab].classList.remove("border-blue-600");
                refs.current[oldTab].classList.add("border-white");
                refs.current[oldTab].classList.remove("text-blue-600");
                refs.current[oldTab].classList.add("text-gray-400");
            }
            refs.current[next].classList.add("border-blue-600");
            refs.current[next].classList.remove("border-white");
            refs.current[next].classList.add("text-blue-600");
            refs.current[next].classList.remove("text-gray-400");
            setOldTab(next);
        },
    };

    const selectTab = (tab) => {
        slider.current.slickGoTo(tab);
        updateSharedData({ policyPageTab: tab });
        scrollToMe.current.scrollIntoView({
            behavior: "instant",
            block: "start",
        });
    };

    // NOTE: Function to filter plans by plan name
    let filteredPlanData = null;
    function filterPlansByPlanName(structuredPlanData, planName) {
        return new Promise((resolve, reject) => {
            filteredPlanData = structuredPlanData.filter((plan) => {
                return plan.planName === planName;
            });
            if (filteredPlanData.length) {
                resolve(filteredPlanData[0]);
            } else {
                reject("Plan not found");
            }
        });
    }

    // NOTE: Function to filter modes by frequency description
    function filterModesByFreqDesc(plan, freqDesc) {
        return new Promise((resolve, reject) => {
            const filtered1 = plan.modes.filter((plan1) => {
                return plan1.freqDesc === freqDesc;
            });
            if (filtered1.length) {
                resolve(filtered1[0]);
            } else {
                reject("Frequency description not found");
            }
        });
    }

    const navigate = useNavigate();
    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    useEffect(() => {
        setTimeout(() => {
            // NOTE: Scroll into top on component render
            scrollToMe.current.scrollIntoView({
                behavior: "instant",
                block: "start",
            });

            let quoteId = null;
            try {
                if (localStorage.getItem("params") === null) {
                    // NOTE: Look for params in the URL
                    const quoteIdParams = searchParams.get("params");
                    let quoteIdDecrypted = quoteIdParams ? decrypt(atob(quoteIdParams)) : null;
                    let quoteType = typeof JSON.parse(quoteIdDecrypted);
                    if (quoteType === "object") quoteId = quoteIdDecrypted;
                    if (quoteType === "number") quoteId = JSON.stringify({ quoteId: parseInt(quoteIdDecrypted), ecId: null });
                    logger.log(location.pathname, "quoteId from URL Path", JSON.parse(quoteId));
                } else {
                    // NOTE: Look for quoteId in the localStorage if not present spit a error. Then redirect back to the Intro page.
                    const quoteIdSess = localStorage.getItem("params");
                    let quoteIdDecrypted = quoteIdSess ? decrypt(atob(quoteIdSess)) : null;
                    let quoteType = typeof JSON.parse(quoteIdDecrypted);
                    if (quoteType === "object") quoteId = quoteIdDecrypted;
                    if (quoteType === "number") quoteId = JSON.stringify({ quoteId: quoteIdDecrypted, ecId: null });
                    logger.log(location.pathname, "quoteId from localStorage", JSON.parse(quoteId));
                }
            } catch (error) {
              logger.log(location.pathname, "error on params supplied", error);
            }

            if (quoteId !== null && quoteId != "null") {
                // NOTE: Trigger useFetchPolicyDetails hook
                const quoteIdFinal = JSON.parse(quoteId);

                setPolicyGetPayload(quoteIdFinal);
            } else {
                logger.log(location.pathname, "", "quoteId is missing");
                updateErrorData({
                    showModal: true,
                    modalType: "error",
                    modalMessage: "Error occured. Please try again later.",
                    redirectUrl: "/intro/?customerId=" + localStorage.getItem("customerId"),
                });
            }
        }, 2000);
    }, []);

    useEffect(() => {
        if (policyDetailsDataFetched && structuredPlanDataFetched && !selectedPlanData) {
            Promise.all([
                filterPlansByPlanName(structuredPlanDataFetched, policyDetailsDataFetched?.planName),
                filterModesByFreqDesc(filteredPlanData[0], policyDetailsDataFetched?.freqDesc),
            ])
                .then((results) => {
                    // NOTE: results[0] contains the filtered plan // results[1] contains the filtered mode
                    updateSelectedPlanData(results[1]);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        if (!isPendingPolicyDetails) {
            // NOTE: Goto the recent selected tab
            if (sharedData.length) {
                if (sharedData[1].policyPageTab !== null) {
                    slider.current.slickGoTo(sharedData[1].policyPageTab, true);
                    refs.current[sharedData[1].policyPageTab].classList.add("border-blue-600");
                    refs.current[sharedData[1].policyPageTab].classList.remove("border-white");
                    refs.current[sharedData[1].policyPageTab].classList.add("text-blue-600");
                    refs.current[sharedData[1].policyPageTab].classList.remove("text-gray-400");
                    setOldTab(sharedData[1].policyPageTab);
                }
            }
        }
    }, [isPending, isPendingPolicyDetails]);

    return (
        <div>
            <div className="h-full">
                <div ref={scrollToMe}></div>
                {!isPendingPolicyDetails && (
                    <div className="bg-slate-100">
                        <Navigator title={"Policy Page"} ifBacked={"#"} />

                        <div className="sticky top-[68px] z-20 bg-white">
                        {/* <div className="sticky top-[0px] z-20 bg-white"> */}
                            <div className="grid grid-cols-2 gap-5 px-5 pt-5">
                                <div className="col-span-1 place-self-center">
                                    <div className="mb-3 place-self-center">
                                        <img src={policypageicon} alt="logo" width={"120px"} />
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="font-bold text-sm mb-2 mt-4 text-blue-950">
                                        <p>INSURED UP TO</p>
                                    </div>
                                    <div className="mb-5 text-blue-950">
                                        <span className="text-md font-bold">PHP </span>
                                        <span className="text-xl font-bold">{numberUtil.formatNumber(policyDetailsData?.maxCov)}</span>
                                    </div>
                                    <div className="text-sm">
                                        <button
                                            className="text-blue-600 font-bold hover:underline"
                                            onClick={() => nextStep("/management/view_contract")}
                                        >
                                            View Contract
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="grid gap-1 px-5 pt-5">
                                <div className="">
                                    <div className="mb-3">
                                        <div className="flex justLify-start gap-2 mb-1">
                                            <div className="place-self-center">
                                                <div className="rounded-full bg-green-500 w-[10px] h-[10px]"></div>
                                            </div>
                                            <p className="font-bold text-xl tracking-normal">{policyDetailsData?.lineName}</p>
                                        </div>
                                        <p className="font-bold text-md tracking-widest">{policyDetailsData?.vrtlAcctNo}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-1 px-5 pt-3 pb-4">
                                <div className="col-span-3">
                                    <div>
                                        <div className="flex justify-start">
                                            <div className="text-xs w-full place-self-center">START DATE:</div>
                                            <div className="text-left font-bold text-sm w-full place-self-center">
                                                {dateUtil.formatDateToReadable(policyDetailsData?.effDate)}
                                            </div>
                                        </div>
                                        <div className="flex justify-start">
                                            <div className="text-xs w-full place-self-center">END DATE:</div>
                                            <div className="text-left font-bold text-sm w-full place-self-center">
                                                {dateUtil.formatDateToReadable(policyDetailsData?.expiryDate)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1 relative">
                                    <div className="absolute bottom-0 right-0 text-sm">
                                        <button className="text-blue-600 font-bold hover:underline" onClick={() => nextStep("/management/need_help")}>
                                            Need help?
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-evenly bg-white border-t shadow-md">
                                {tabs &&
                                    tabs.map((tab, tabindex) => {
                                        return (
                                            <button
                                                className={`text-center py-4 w-1/3 font-bold text-md border-b-2 hover:cursor-pointer ${
                                                    tab.id === oldTab ? "border-blue-600 text-blue-600" : "border-white text-gray-400"
                                                }`}
                                                key={tabindex}
                                                ref={(element) => {
                                                    refs.current[tab.id] = element;
                                                }}
                                                onClick={() => {
                                                    selectTab(tabindex);
                                                }}
                                            >
                                                {tab.title}
                                            </button>
                                        );
                                    })}
                            </div>
                        </div>

                        <Slider ref={slider} {...settings}>
                            <div>
                                <Overview
                                    policyDetailsData={policyDetailsData}
                                    numberUtil={numberUtil}
                                    dateUtil={dateUtil}
                                    showModalStateVb={showModalStateVb}
                                    setShowModalStateVb={setShowModalStateVb}
                                />
                            </div>
                            <div>
                                <Services
                                    setShowModalState={setShowModalState}
                                    selectTab={selectTab}
                                    myIndex={2}
                                    fromLsq={policyDetailsData?.fromLsq}
                                />
                            </div>
                            <div>
                                <Activity policyDetailsData={policyDetailsData} />
                            </div>
                        </Slider>
                        <ViewBreakdown showModalStateVb={showModalStateVb} setShowModalStateVb={setShowModalStateVb} />
                    </div>
                )}
                {isPendingPolicyDetails && <Skeleton />}
            </div>
        </div>
    );
};

export default Index;
