import logger from "utils/logger";

const cryptoJs = require("./node-cryptojs-aes/cryptoJS").CryptoJS;
let cryptoKey = "";
const url = window.location.hostname === "localhost" ? "https://zk7l3wtn0j.execute-api.ap-southeast-1.amazonaws.com/app" : "/app";
const frontUrl = window.location.hostname === "localhost" ? "" : `https://${window.location.hostname}`;
const testNumber = 0;
const dataUrl = "/7LWRZxbxtWCYP5rEMjllXg.json";
const cryptoUrl = "/CkmbNelAbgtl4x-AIYSk8g.json";

const testData = [
    {
        api: "kyc/get",
        payload: {
            insuranceAccountId: "49TnMeyszCiMifcqrFXr7EIv3ZnbSkfqU0q/AYzzTxY=",
        },
    },
    {
        api: "quotation/save",
        payload: {
            customerInfo: {
                insuranceAccountId: "210700400000000000648685138",
                address: {
                    city: "METRO MANILA",
                    country: "PHILIPPINES",
                    province: "METRO MANILA",
                    street: "09565550023",
                    town: "CITY OF MUNTINLUPA",
                    zipCode: "1773",
                    address1: "09565550023 STREET CITY OF MUNTINLUPA ME",
                    address2: "TRO MANILA METRO MANILA PHILIPPINES 1773",
                },
                birthday: "1990-12-24",
                emailAddress: "Hiphop558@gmail.com",
                firstName: "GGIVES",
                lastName: "GCREDITD",
                middleName: "LOANS",
                mobileNumber: "09565550023",
                nationality: "FILIPINO",
                prefix: "",
                displayName: "GGIVES LOANS GCREDITD",
            },
            productInfo: {
                lineCd: "PC",
                sublineCd: "PCI",
                planCd: 2,
                freqCd: 1,
                vrtlAcctNo: 928182736537,
            },
        },
    },
    {
        api: "order/create",
        payload: {
            insuranceAccountId: "210700400000000000648685993",
            quoteId: 209,
            premAmt: 10.0,
        },
    },
    {
        api: "policy/get",
        payload: {
            polId: 290,
        },
    },
    {
        api: "policy/flag/renew/save",
        payload: {
            policyId: 290,
            autoRenew: true,
        },
    },
    {
        api: "mail/send",
        payload: {
            policyId: 298,
            quoteId: 639,
        },
    },
    {
        api: "report/get",
        payload: {
            quoteId: 2116,
        },
    },
];

function isBase64(str) {
    try {
        return btoa(atob(str)) == str;
    } catch (e) {
        return false;
    }
}

async function getCryptoKeyOrig() {
    await fetch(frontUrl + cryptoUrl)
        .then((response) => response.text())
        .then((data) => {
            for (let a = 1; a <= data.length / 5; a++) {
                cryptoKey += data.substr(5 * a - 1, 1);
            }
        });
}

async function getCryptoKey() {
    await fetch(frontUrl + cryptoUrl)
        .then((response) => response.text())
        .then((data) => {
            for (let a = 1; a <= data.length / 5; a++) {
                cryptoKey += data.substr(5 * a - 1, 1);
            }
            const isValid = isBase64(cryptoKey);
            if (!isValid) {
                if (window.location.pathname !== "/maintenance_page") {
                    window.location.replace("/maintenance_page");
                }
                logger.log("decrypt.js getCryptoKey", "key invalid", null);
            } else {
                logger.log("decrypt.js getCryptoKey", "key valid", cryptoKey);
            }
        });
}

async function restoreCryptoKey() {
    try {
        const cryptoKeyLocal = await fetch(frontUrl + cryptoUrl);
        const cryptoText = await cryptoKeyLocal.text();
        cryptoKey = "";
        let key = null;

        for (let a = 1; a <= cryptoText.length / 5; a++) {
            cryptoKey += cryptoText.substr(5 * a - 1, 1);
        }

        const isValid = isBase64(cryptoKey);

        if (!isValid) {
            key = cryptoKey;
            logger.log("decrypt.js restoreCryptoKey", "key valid", cryptoKey);
        } else {
            key = null;
            logger.log("decrypt.js restoreCryptoKey", "key invalid", null);
        }

        return key;
    } catch (error) {
        logger.log("derypt.js", "error", error);
    }
}

async function getPlanData() {
    const planData = fetch(frontUrl + dataUrl)
        .then((response) => response.text())
        .then((data) => {
            const isValid = isBase64(data);
            let returnData = null;
            if (!isValid) {
                if (window.location.pathname !== "/maintenance_page") {
                    window.location.replace("/maintenance_page");
                }
                logger.log("decrypt.js", "plan data invalid", null);
                returnData = null;
            } else {
                returnData = JSON.parse(decrypt(data));
                logger.log("decrypt.js", "plan data valid", returnData);
            }
            return returnData;
        });
    return planData;
}

function encrypt(data) {
    if (data === undefined) {
        data = null;
    }
    return cryptoJs.AES.encrypt(JSON.stringify(data), cryptoKey).toString();
}

function decrypt(datadec) {
    return cryptoJs.AES.decrypt(datadec, cryptoKey).toString(cryptoJs.enc.Utf8);
}

function buildCsrfToken(requestParameter) {
    const token1 = createToken(requestParameter);
    const token2 = createToken(token1);
    const token3 = createToken(token2);
    return `${token1}.${token2}.${token3}`;
}

function createToken(data) {
    return encrypt(data.substr(30, 1) + data.substr(10, 1) + data.substr(40, 1) + data.substr(15, 1) + data.substr(35, 1) + data.substr(5, 1) + data.substr(25, 1) + data.substr(20, 1));
}

// static encryption and csrf build from the payload above.
const encryptedParams = encrypt(testData[testNumber].payload);
const csrfToken = buildCsrfToken(encryptedParams);

// dynamic encryption and csrf build from the passed payload
const encryptedParamsDynamic = (payload_supplied) => {
    return encrypt(payload_supplied);
};

if (cryptoKey === "") getCryptoKey();
getPlanData();

export { buildCsrfToken, createToken, restoreCryptoKey, getPlanData, cryptoKey, cryptoUrl, dataUrl, decrypt, encrypt, encryptedParamsDynamic, testData, testNumber, url };
