import { useEffect, useState } from "react";
import { decryptOrderCreateSaveResponse } from "services/order-create";
import { decryptQuotationSaveResponse } from "services/quotation-save";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";

const useFetchQuotationSave = (quotationData) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { kycData, customerId } = useDataContext();
    const { updateErrorData } = useNotifContext();

    async function submitQuotationSave(payload) {
        setIsPending(true);
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        await decryptQuotationSaveResponse(payload)
            .then((data) => {
                if (data?.length) {
                    if (data[0].status === "SUCCESS") {
                        logger.log("useFetchQuotationSave", "quotation/save success", data[1].data);
                        const orderCreatePayload = {
                            insuranceAccountId: kycData?.insuranceAccountId,
                            quoteId: data[1]?.data?.quoteId,
                            // premAmt: data[1]?.data?.premAmt || 1,
                            freqCd: payload[0]?.productInfo?.freqCd,
                            planCd: payload[0]?.productInfo?.planCd,
                        };
                        logger.log("useFetchQuotationSave", "order/create payload", orderCreatePayload);
                        submitOrderCreate(orderCreatePayload)
                            .then((response) => {
                                if (response[1]?.checkoutUrl !== undefined) {
                                    window.location.href = response[1]?.checkoutUrl;
                                    setData(response[1]);
                                    setIsPending(false);
                                    logger.log("useFetchQuotationSave", "order/create success", response[1]);
                                } else {
                                    setIsPending(false);
                                    logger.log("useFetchQuotationSave", "order/create error", response[1]);
                                    throw new Error("Something went wrong.");
                                }
                            })
                            .catch((error) => {
                                logger.log("useFetchQuotationSave - order create", "Catched Error", error);
                                if (error.message === "TIMEOUT REACHED") {
                                    updateErrorData({
                                        showModal: true,
                                        modalType: "error",
                                        modalMessage: "Application Timeout.",
                                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                                    });
                                } else {
                                    updateErrorData({
                                        showModal: true,
                                        modalType: "error",
                                        modalMessage: "Something went wrong.",
                                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                                    });
                                }
                                setIsPending(false);
                            });
                    } else {
                        setIsPending(false);
                        throw new Error(data[0]?.status || "Something went wrong");
                    }
                } else {
                    setIsPending(false);
                    throw new Error("Something went wrong.");
                }
            })
            .catch((error) => {
                logger.log("useFetchQuotationSave", "Catched Error", error);
                if (error.message === "TIMEOUT REACHED") {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Application Timeout.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                } else {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Something went wrong.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                }
                setIsPending(false);
            });
    }

    async function submitOrderCreate(payload) {
        const orderdata = await decryptOrderCreateSaveResponse(payload);
        if (orderdata?.length) {
            if (orderdata[0].status === "SUCCESS") {
                return orderdata;
            } else {
                throw new Error(orderdata[0]?.status || "order create failed");
            }
        } else {
            throw new Error("order create failed");
        }
    }

    useEffect(() => {
        if (quotationData) {
            logger.log("useFetchQuotationSave", "quotation payload", quotationData);
            submitQuotationSave(quotationData);
        }
    }, [quotationData]);

    return { data, isPending };
};

export default useFetchQuotationSave;
