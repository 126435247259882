import Navigator from "components/Navigator";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import useReportGet from "hooks/useReportGet";
import Skeleton from "components/Skeleton";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewContract = () => {
    const [totalPages, setTotalPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageScale, setPageScale] = useState(0.7);
    const { policyDetailsData } = useDataContext();
    const [fileUrl, setFileUrl] = useState(null);
    const { updateErrorData } = useNotifContext();

    const [quoteId, setQuoteId] = useState(null);
    const { data, isPending } = useReportGet(quoteId);

    const getReport = (quoteId) => {
        if (quoteId !== undefined) {
            setQuoteId(quoteId);
        }
    };

    useEffect(() => {
        getReport(policyDetailsData?.quoteId);
    }, []);

    useEffect(() => {
        if (!isPending) {
            if (data !== null && data[0].status === "SUCCESS" && data[1].body.report !== "error") {
                setFileUrl("data:application/pdf;base64," + data[1].body.report);
            } else {
                updateErrorData({
                    showModal: true,
                    modalType: "warning",
                    modalMessage: "Something went wrong while processing the pdf. Please try again later.",
                    redirectUrl: "goback",
                });
            }
        }
    }, [isPending]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPages(numPages);
    };

    function handleZoomIn() {
        if (pageScale < 3) {
            setPageScale(pageScale + 0.2);
        }
    }

    function handleZoomOut() {
        if (pageScale > 0.3) {
            setPageScale(pageScale - 0.2);
        }
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div>
            {!isPending && (
                <div className="bg-slate-100 h-screen">
                    <Navigator title={"Policy Contract"} useRouterHistory={true} />
                    <div className="flex flex-col bg-slate-100">
                        {/* <div className="p-5">
                        <div className="float-right">
                            <img src={download} alt="logo" width={"30px"} />
                        </div>
                    </div> */}

                        <div className="text-lg font-bold text-gray-500 text-center p-5">
                            <div className="border-2 h-[560px] max-h-[575px] pdf-container" style={{ overflow: "auto" }}>
                                {/* <Document file={attachment.url} onLoadSuccess={onDocumentLoadSuccess} className="PDFDocument"> */}
                                <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} noData="Something went wrong while processing the pdf. Please try again later.">
                                    {/* {Array.apply(null, Array(totalPages))
                                    .map((x, i) => i + 1)
                                    .map((page) => (
                                        <Page pageNumber={page} key={page} />
                                        ))} */}
                                    <Page scale={pageScale} pageNumber={pageNumber} key={pageNumber} />
                                </Document>
                            </div>
                        </div>

                        <div className="flex flex-row justify-center h-full">
                            <div className="button-container">
                                <button
                                    onClick={handleZoomIn}
                                    disabled={pageScale >= 3}
                                    className={`px-5 py-2 border-y-2 border-l-2 bg-white 
                            ${pageScale >= 3 ? "text-gray-200" : ""}
                            `}>
                                    +
                                </button>
                                <button
                                    onClick={handleZoomOut}
                                    disabled={pageScale <= 0.7}
                                    className={`px-5 py-2 border-y-2 border-x-2 bg-white 
                             ${pageScale <= 0.7 ? "text-gray-200" : ""}
                             `}>
                                    -
                                </button>
                            </div>
                        </div>

                        {/* <div className="mt-5 mb-8 flex flex-row justify-center">
                        <button
                            type="button"
                            className={`px-5 py-2 border-y-2 border-l-2 bg-white 
                            ${pageNumber <= 1 ? "text-gray-200" : ""}
                            `}
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            {"<"}
                        </button>
                        <div className="px-5 py-2 border-2 bg-white">
                            <p>
                                {pageNumber || (totalPages ? 1 : "--")} of {totalPages || "--"}
                            </p>
                        </div>
                        <button
                            type="button"
                            className={`px-5 py-2 border-y-2 border-r-2 bg-white 
                            ${pageNumber >= totalPages ? "text-gray-200" : ""}`}
                            disabled={pageNumber >= totalPages}
                            onClick={nextPage}
                        >
                            {">"}
                        </button>
                    </div> */}
                    </div>
                </div>
            )}

            {isPending && <Skeleton />}
        </div>
    );
};

export default ViewContract;
