const tabs = [
    {
        id: 0,
        title: "Overview",
    },
    {
        id: 1,
        title: "Services",
    },
    {
        id: 2,
        title: "Activity",
    },
];

export { tabs };
