import NeedHelp from "components/NeedHelp.js";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "store/DataContext";

const Overview = ({ policyDetailsData, numberUtil, dateUtil, showModalStateVb, setShowModalStateVb }) => {
    const navigate = useNavigate();
    const { customerId } = useDataContext();

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    let capitalizedFreqUnit = capitalizeFirstLetter(policyDetailsData.freqUnit);

    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    return (
        <div>
            <div className="flex flex-col h-full text-blue-950">
                <div className="mb-auto">
                    <div className="mt-2 bg-white">
                        <div className="px-5 py-5 border-y border-gray-100">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-lg font-bold place-self-center">Coverage Details</p>
                                </div>
                                <div></div>
                            </div>
                        </div>

                        <div className="px-5 mt-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Status</p>
                                <p className="text-lg font-bold text-right opacity-90">Active</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Protection Plan</p>
                                <p className="text-lg font-bold text-right opacity-90">{policyDetailsData && policyDetailsData?.planName}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Protection Period</p>
                                <p className="text-lg font-bold text-right opacity-90">{policyDetailsData && policyDetailsData?.freqDesc}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Total Aggregate <br/> Benefit Amount</p>
                                <p className="text-lg font-bold text-right opacity-90">
                                    up to PHP {policyDetailsData && numberUtil.formatNumber(policyDetailsData?.maxCov)}
                                </p>
                            </div>
                        </div>

                        <div className="px-5 mt-3 pb-5">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Duration</p>
                                <p className="text-lg font-bold text-right opacity-90">
                                    {policyDetailsData && policyDetailsData?.monthCount} month/s
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2 bg-white">
                        <div className="px-5 py-5 border-b border-gray-100">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-lg font-bold">Premium</p>
                                </div>
                                <div></div>
                            </div>
                        </div>

                        <div className="px-5 mt-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Payment Plan</p>
                                <p className="text-lg font-bold text-right opacity-90">{policyDetailsData && policyDetailsData?.freqDesc}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Amount</p>
                                <p className="text-lg font-bold text-right opacity-90">
                                    PHP {policyDetailsData && policyDetailsData?.totPrem} / {capitalizedFreqUnit}
                                </p>
                            </div>
                        </div>

                        <div className="px-5 mb-7">
                            <div className="flex justify-between">
                                <p className="text-md place-self-center">Next Due Date</p>
                                <p className="text-lg font-bold text-right opacity-90">
                                    {policyDetailsData && dateUtil.formatDateToReadable(policyDetailsData?.expiryDate)}
                                </p>
                            </div>
                        </div>

                        <div className="px-5 pt-5 pb-5 border-t border-gray-100">
                            <div className="flex justify-center">
                                {/* <button
                                    className="text-md text-blue-600 font-bold"
                                    onClick={() => {
                                        nextStep("/purchase/view_breakdown");
                                    }}
                                >
                                    View Breakdown
                                </button> */}
                                <button
                                    className={`text-md font-bold hover:underline ${policyDetailsData?.fromLsq ? "text-blue-200" : "text-blue-600"}`}
                                    onClick={() => {
                                        setShowModalStateVb(!showModalStateVb);
                                    }}
                                    disabled={policyDetailsData?.fromLsq}
                                >
                                    View Breakdown
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-2 bg-white">
                        <div className="px-5 py-5 border-b border-gray-100">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-lg font-bold">Insured</p>
                                </div>
                                <div></div>
                            </div>
                        </div>

                        <div className="px-5 pt-5 pb-8 border-gray-300">
                            <div className="flex justify-between">
                                <p className="text-lg font-bold">{(policyDetailsData && policyDetailsData?.displayName) || "---"}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <NeedHelp />
                </div>
            </div>
        </div>
    );
};

export default Overview;
