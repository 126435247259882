import Header from "components/Header";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import ViewBreakdown from "components/ViewBreakdown";
import useFetchQuotationSave from "hooks/useFetchQuotationSave";
import useSetNextDueDate from "hooks/useSetNextDueDate";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decrypt, restoreCryptoKey } from "services/decrypt";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import { numberUtil } from "utils/numberutil";

const CoverageDetails = () => {
    const { updateErrorData } = useNotifContext();
    let { kycData, customerId, quotationData, selectedPlanData, updateSelectedPlanData, updateQuotationData, updateKyc, updateQuotationDataFull } = useDataContext();
    const scrollToMe = useRef();
    const [accept, setAccept] = useState(false);
    const [showModalState, setShowModalState] = useState(false);
    const [showModalStateVb, setShowModalStateVb] = useState(false);
    const [frequency, setFrequency] = useState(null);
    const { data: nextDueDate, isPending: isPendingNextDueDate } = useSetNextDueDate(frequency);
    const [quotationPayload, setQuotationPayload] = useState(null);
    const { data: quotationSaveData, isPending: isPendingQuotationSave } = useFetchQuotationSave(quotationPayload);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pendingLocal, setIsPendingLocal] = useState(true);
    const [quotationDataLocal, setQuotationDataLocal] = useState([]);

    const navigate = useNavigate();
    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    const transactionSubmit = () => {
        const toPass = quotationDataLocal.length ? quotationDataLocal : quotationData;
        if (accept && selectedPlanData) {
            setQuotationPayload(toPass);
        } else {
            setQuotationPayload(null);
            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: "Please accept Terms & Condition to proceed.",
                redirectUrl: null,
            });
        }
    };

    const openDefaultBrowser = () => {
        window.location.assign("https://bpims.com/mp-gcppci/", "_system");
    };

    const restoreData = async () => {
        if (selectedPlanData === null || !quotationData.length) {
            await restoreCryptoKey();
            let selectedPlanDataLocal = JSON.parse(decrypt(localStorage.getItem("selectedPlanData")));
            let quotationDataLocalScoped = JSON.parse(decrypt(localStorage.getItem("quotationData")));
            let kycDataLocal = JSON.parse(decrypt(localStorage.getItem("kycData")));
            // setFrequency(selectedPlanData?.freqUnit);
            setFrequency({
                expDate: kycDataLocal.selectedVrtlAcctNo?.policyId || kycDataLocal.selectedVrtlAcctNo?.quoteId !== null ? kycDataLocal.selectedVrtlAcctNo?.expDate : null,
                freqUnit: selectedPlanDataLocal?.freqUnit,
            });
            setQuotationDataLocal(quotationDataLocalScoped);
            updateSelectedPlanData(selectedPlanDataLocal);
            updateQuotationDataFull(quotationDataLocalScoped);
            updateKyc(kycDataLocal);
            setIsPendingLocal(false);
        } else {
            // setFrequency(selectedPlanData?.freqUnit);
            setFrequency({
                expDate: kycData.selectedVrtlAcctNo?.policyId || kycData.selectedVrtlAcctNo?.quoteId !== null ? kycData.selectedVrtlAcctNo?.expDate : null,
                freqUnit: selectedPlanData?.freqUnit,
            });
            setIsPendingLocal(false);
        }
    };

    useEffect(() => {
        restoreData();

        scrollToMe.current.scrollIntoView({
            behavior: "instant",
            block: "start",
        });

        // if (selectedPlanData !== null) {
        //     setTimeout(() => {
        //         setFrequency(selectedPlanData?.freqUnit);
        //         setIsPendingLocal(false);
        //     }, 1000);
        // }
    }, []);

    return (
        <div>
            <div ref={scrollToMe}></div>
            {!isPendingQuotationSave && !quotationSaveData && selectedPlanData && !pendingLocal && !isPendingNextDueDate && (
                <div className="flex flex-col h-screen">
                    <div className="h-auto mb-auto" style={{ overflow: "auto" }}>
                        <Navigator title={"Coverage"} useRouterHistory={true} />
                        <Header currentStep={4}></Header>

                        <div className="">
                            <div className="px-5 py-5 border-b border-gray-100">
                                <div className="flex justify-between gap-3">
                                    <div>
                                        <p className="text-lg font-bold place-self-center">Coverage Details</p>
                                    </div>
                                    <div className="place-self-center">
                                        <button
                                            className="text-md font-bold text-blue-600 hover:underline"
                                            onClick={() => {
                                                nextStep("/purchase/select_plan");
                                            }}>
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="px-5 pt-5 mb-5">
                                <div className="flex justify-between gap-3">
                                    <p className="text-md opacity-90 place-self-center">Status</p>
                                    <p className="text-md font-bold text-right place-self-center">----</p>
                                </div>
                            </div> */}

                            <div className="px-5 pt-5 mb-5">
                                <div className="flex justify-between gap-3">
                                    <p className="text-md opacity-90 place-self-center">Protection Plan</p>
                                    <p className="text-md font-bold text-right place-self-center">{selectedPlanData && selectedPlanData?.planName}</p>
                                </div>
                            </div>

                            <div className="px-5 mb-5">
                                <div className="flex justify-between gap-3">
                                    <p className="text-md opacity-90 place-self-center">Protection Period</p>
                                    <p className="text-md font-bold text-right place-self-center">{selectedPlanData && selectedPlanData.freqDesc}</p>
                                </div>
                            </div>

                            <div className="px-5 mb-5">
                                <div className="flex justify-between gap-3">
                                    <p className="text-md opacity-90 place-self-center">Total Aggregate Benefit Amount</p>
                                    <p className="text-md font-bold text-right place-self-center">up to PHP {selectedPlanData && numberUtil.formatNumber(selectedPlanData.maxCov)}</p>
                                </div>
                            </div>

                            <div className="px-5 mb-5">
                                <div className="flex justify-between gap-3">
                                    <p className="text-md opacity-90 place-self-center">Duration</p>
                                    <p className="text-lg font-bold text-right place-self-center">
                                        {selectedPlanData?.freqUnit === "year" && `12 months`}
                                        {selectedPlanData?.freqUnit === "month" && `1 month`}
                                        {selectedPlanData?.freqUnit === "semi-annum" && `6 months`}
                                    </p>
                                </div>
                            </div>

                            <div className="px-5 py-5 border-y border-gray-100">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="text-lg font-bold">Premium</p>
                                    </div>
                                    <div className="place-self-center">
                                        <button
                                            className="text-md font-bold text-blue-600 hover:underline"
                                            onClick={() => {
                                                nextStep("/purchase/select_plan");
                                            }}>
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="px-5 mt-5 mb-5">
                                <div className="flex justify-between">
                                    <p className="text-md opacity-90">Payment Plan</p>
                                    <p className="text-md font-bold text-right">{selectedPlanData && selectedPlanData.freqDesc}</p>
                                </div>
                            </div>

                            <div className="px-5 mb-5">
                                <div className="flex justify-between">
                                    <p className="text-md opacity-90">Amount</p>
                                    <p className="text-md font-bold text-right">
                                        {selectedPlanData.freqUnit === "year" && `PHP ${selectedPlanData.totPrem} / year`}
                                        {selectedPlanData.freqUnit === "month" && `PHP ${selectedPlanData.totPrem} / month`}
                                        {selectedPlanData.freqUnit === "semi-annum" && `PHP ${selectedPlanData.totPrem} / 6 months`}
                                    </p>
                                </div>
                            </div>

                            <div className="px-5 mb-5">
                                <div className="flex justify-between">
                                    <p className="text-md opacity-90">Next Due Date</p>
                                    <p className="text-md font-bold text-right">{selectedPlanData && nextDueDate}</p>
                                </div>
                            </div>

                            <div className="px-5 py-5 border-t border-gray-100">
                                <div className="flex justify-center">
                                    {/* <button
                                        className="text-md font-bold text-blue-600"
                                        onClick={() => {
                                            nextStep("/purchase/view_breakdown");
                                        }}
                                    >
                                        View Breakdown
                                    </button> */}
                                    <button
                                        className="text-md font-bold text-blue-600 hover:underline"
                                        onClick={() => {
                                            setShowModalStateVb(!showModalStateVb);
                                        }}>
                                        View Breakdown
                                    </button>
                                </div>
                            </div>

                            <div className="px-5 py-5 border-y border-gray-100">
                                <div className="flex justify-between">
                                    <div>
                                        <p className="text-lg font-bold">Insured</p>
                                    </div>
                                    <div></div>
                                </div>
                            </div>

                            <div className="px-5 pt-5 pb-8 border-gray-100">
                                <div className="flex justify-between">
                                    <p className="text-lg font-bold">{kycData && kycData.displayName}</p>
                                </div>
                            </div>

                            <div className="px-5 py-5">
                                <div className="">
                                    <p className="text-md font-bold mb-5">Default</p>
                                    <p className="text-md opacity-90">All benefits shall be paid to you by check.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-slate-100 border-t">
                        <div className="px-5 pb-5">
                            <div className="mt-5">
                                <p className="text-lg font-bold mb-5">Declarations</p>
                                <div className="flex gap-8 px-5">
                                    <div className="place-self-center">
                                        <input
                                            type="checkbox"
                                            className="w-[25px] h-[25px]"
                                            checked={accept}
                                            onChange={function (e) {
                                                setAccept(!accept);
                                            }}
                                        />
                                    </div>
                                    <div className="text-md leading-relaxed place-self-center">
                                        <span className="">I have read the </span>
                                        <a
                                            className="text-blue-600 font-bold hover:underline cursor-pointer"
                                            // onClick={() => setShowModalState(!showModalState)}
                                            onClick={() => openDefaultBrowser()}>
                                            Full Policy Terms and Conditions.
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-6 pb-6">
                            <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => transactionSubmit()}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isPendingQuotationSave && <Skeleton />}
            {pendingLocal && <Skeleton />}
            {isPendingNextDueDate && <Skeleton />}

            <ViewBreakdown showModalStateVb={showModalStateVb} setShowModalStateVb={setShowModalStateVb} />
        </div>
    );
};

export default CoverageDetails;
