import { useEffect, useState } from "react";
import { HiOutlineCheckCircle, HiOutlineExclamationCircle, HiXCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useNotifContext } from "store/NotifContext";
const ModalPrompt = () => {
    const { errorData, updateErrorData } = useNotifContext();

    const navigate = useNavigate();
    const [showModalState, setShowModalState] = useState(false);
    const [modalTypeState, setModalTypeState] = useState(false);
    const [modalMessageState, setModalMessage] = useState(false);
    const [formattedError, setFormattedError] = useState([]);

    const props = {
        showModalState,
        setShowModalState,
        modalTypeState,
        setModalTypeState,
        modalMessageState,
        setModalMessage,
    };

    const onCloseAction = () => {
        props.setShowModalState(false);
        if (errorData.redirectUrl != null) {
            if (errorData.redirectUrl == "goback") {
                navigate(-1);
            } else {
                navigate(errorData.redirectUrl);
            }
        }
        updateErrorData({});
    };

    const formatError = () => {
        if(errorData?.modalMessage !== undefined){
            const errorFormatted = errorData?.modalMessage;
            setFormattedError(errorFormatted.split('\n'));
        }
    }

    useEffect(() => {
        setShowModalState(errorData?.showModal);
        setModalTypeState(errorData?.modalType);
        setModalMessage(errorData?.modalMessage);
        formatError();
    }, [errorData]);

    return (
        <div className="">
            {showModalState && (
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="bg-black w-full h-full absolute opacity-40"></div>
                    <div className="relative w-auto my-6 max-w-3xl mx-5">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="relative p-6 flex-auto">
                                <div className="px-6 py-12">
                                    {modalTypeState === "error" && <HiXCircle className="mx-auto mb-4 h-20 w-20 text-red-500" />}
                                    {modalTypeState === "success" && <HiOutlineCheckCircle className="mx-auto mb-4 h-20 w-20 text-green-500" />}
                                    {modalTypeState === "warning" && <HiOutlineExclamationCircle className="mx-auto mb-4 h-20 w-20 text-orange-500" />}
                                    {!errorData?.withFormatting && <p className="text-center text-lg leading-relaxed text-blue-950">{modalMessageState && modalMessageState}</p>}

                                    {errorData?.withFormatting &&
                                        formattedError.map((line, index) => (
                                            <div key={index} className="text-center text-lg leading-relaxed text-blue-950">
                                                {index !== 2 && line}
                                                {index === 2 && <strong>{line}</strong>}
                                            </div>
                                        ))}

                                    {errorData?.fromGcash && <p className="mt-5 text-md text-center leading-tight">{errorData?.modalGcashMessage}</p>}
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    type="button"
                                    className="text-white w-full mb-2 bg-defaultblue border-defaultblue border-4 hover:bg-blue-800 hover:border-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={() => onCloseAction()}>
                                    {errorData?.buttonText || "Okay"}
                                </button>
                                {errorData?.buttonExit && (
                                    <button
                                        type="button"
                                        className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none  font-medium rounded-full text-sm px-5 py-2.5 text-center"
                                        onClick={() => setShowModalState(!showModalState)}>
                                        Exit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModalPrompt;
