import likelogo from "assets/images/like-logo.png";
import Navigator from "components/Navigator";
import NeedHelp from "components/NeedHelp.js";
import Skeleton from "components/Skeleton";
import useFetchPolicyDetails from "hooks/useFetchPolicyDetails";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { decrypt } from "services/decrypt";
import { useDataContext } from "store/DataContext";
import { dateUtil } from "utils/dateutil";
import logger from "utils/logger";
import { numberUtil } from "utils/numberutil";

const Success = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { policyDetailsData } = useDataContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const [quoteId, setQuoteId] = useState(null);
    const { data, isPending } = useFetchPolicyDetails(quoteId);

    const getUrlParams = () => {
        const decodeParamsToBase64 = atob(searchParams.get("params"));
        const decryptParams = decrypt(decodeParamsToBase64);
        const quoteIdLocal = JSON.parse(decryptParams);
        setQuoteId(quoteIdLocal);
        logger.log(location.pathname, "passed for policy/get payload:", quoteIdLocal);
    };

    const nextStep = () => {
        const customerIdParams = localStorage.getItem("customerId") !== null ? localStorage.getItem("customerId") : searchParams.get("customerId");
        navigate(`/management/?customerId=${customerIdParams}`);
    };

    useEffect(() => {
        getUrlParams();
    }, []);

    return (
        <div>
            {!isPending && policyDetailsData && (
                <div className="flex flex-col h-full">
                    <Navigator title={"GInsure x BPI MS"} ifBacked={"#"} />
                    <div className="flex justify-center mt-8">
                        <div className="bg-blue-50 rounded-full p-3">
                            <img src={likelogo} alt="Like logo" />
                        </div>
                    </div>

                    <div className="px-5 mt-8 text-center text-2xl font-bold text-blue-500">
                        <p>Congratulations! You are now covered.</p>
                    </div>

                    <div className="px-12 mt-5 mb-10 text-center text-md opacity-90 leading-relaxed">
                        {/* <p>
                            Here's your coverage details. We've sent the welcome email to{" "}
                            <span className="text-blue-500">
                                {policyDetailsData && policyDetailsData?.emailAddress && policyDetailsData?.emailAddress.toLowerCase()}
                            </span>{" "}
                            Check your spam as well!
                        </p> */}
                        <p>
                            Here are your coverage details. A confirmation of your insurance coverage will be sent to your registered email address.
                            {/* <span className="text-blue-500">
                                {" "}
                                {policyDetailsData && policyDetailsData?.emailAddress && policyDetailsData?.emailAddress.toLowerCase()}{" "}
                            </span> */}{" "}
                            Please check your spam folder as well.
                        </p>
                    </div>

                    <div className="px-5 mb-10">
                        <div className="border border-dashed"></div>
                    </div>

                    <div className="px-2 mb-auto">
                        <div className="px-5 mt-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md opacity-90 place-self-center">Policy</p>
                                <p className="text-lg font-bold place-self-center text-right text-blue-500">{policyDetailsData && policyDetailsData?.lineName}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md opacity-90 place-self-center">Protection Plan</p>
                                <p className="text-lg font-bold place-self-center text-blue-500 text-right">{policyDetailsData && policyDetailsData?.planName}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md opacity-90 place-self-center">Protection Period</p>
                                <p className="text-lg font-bold place-self-center text-blue-500 text-right">{policyDetailsData && policyDetailsData?.freqDesc}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md opacity-90 place-self-center">Amount Paid</p>
                                <p className="text-lg font-bold place-self-center text-blue-500 text-right">PHP {policyDetailsData && numberUtil.formatNumber(policyDetailsData?.totPrem)}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-3">
                            <div className="flex justify-between">
                                <p className="text-md opacity-90 place-self-center">GCash Ref No.</p>
                                <p className="text-lg font-bold place-self-center text-blue-500 text-right">{(policyDetailsData && policyDetailsData?.transactionId) || "N/A"}</p>
                            </div>
                        </div>

                        <div className="px-5 mb-10">
                            <div className="flex justify-between">
                                <p className="text-md opacity-90 place-self-center">Next Due Date</p>
                                <p className="text-lg font-bold place-self-center text-blue-500 text-right">{policyDetailsData && dateUtil.formatDateToReadable(policyDetailsData?.expiryDate)}</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <NeedHelp />
                        <div className="px-5 mb-10">
                            <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => nextStep()}>
                                View Policy Page
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isPending && <Skeleton />}
        </div>
    );
};

export default Success;
