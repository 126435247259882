import banner from "assets/images/banners/GCash-Card-Protect-Banner.png";
import Header from "components/Header";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import ViewBreakdown from "components/ViewBreakdown";
import useFetchPlanData from "hooks/useFetchPlanData";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { encrypt, decrypt, restoreCryptoKey } from "services/decrypt";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";
import { reactSlickVar } from "utils/variables/reactSlickVar";
import SelectPlanCard from "./SelectPlanCard";

const SelectPlan = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { updateErrorData } = useNotifContext();
    const { customerId, quotationData, selectedPlanData, kycData, updateQuotationData } = useDataContext();
    const scrollToMe = useRef();
    const [autoRenewal, setAutoRenewal] = useState(false);
    const { data: structuredPlanData, isPending } = useFetchPlanData();
    const [isPendingLocal, setIsPendingLocal] = useState(true);
    const [showModalStateVb, setShowModalStateVb] = useState(false);

    reactSlickVar.adaptiveHeight = true;

    const nextProcess = () => {
        if (selectedPlanData) {
            // NOTE: append the autoRenewal to the payload that will be used in quotation/save
            if (quotationData[0]?.productInfo !== undefined) {
                let appendAutoRenewTag = quotationData[0].productInfo;
                appendAutoRenewTag.autoRenewTag = autoRenewal;
                logger.log(location.pathname, "auto renew tag appended. on nextbtn clicked", null);
                logger.log(location.pathname, "quotation Data", quotationData);
            }
            const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
            localStorage.setItem("kycData", encrypt(kycData));
            localStorage.setItem("selectedPlanData", encrypt(selectedPlanData));
            localStorage.setItem("quotationData", encrypt(quotationData));
            navigate("/purchase/summary/?customerId=" + customerIdParams);
        } else {
            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: "Please select Plan to proceed.",
                redirectUrl: null,
            });
        }
    };

    // inspect quotationData context if empty. if quotationData is empty its due to page refresh.
    // restore qoutationData customerId & customerInfo if not existing. using kycData from the localStorage
    const checkKycData = async () => {
        await restoreCryptoKey();
        // If kycData in the Context is missing. Check if theres available in localStorage
        const kycDataLocal = kycData || JSON.parse(decrypt(localStorage.getItem("kycData")));
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        // update the customerId & customerInfo key in the quotationData
        // update the customerInfo key in the quotationData
        updateQuotationData([{ customerId: customerIdParams }, { customerInfo: { emailAddress: kycDataLocal?.emailAddress, ...kycDataLocal?.kycInputs } }]);
    };

    useEffect(() => {
        if (structuredPlanData?.length > 1) {
            reactSlickVar.initialSlide = 1;
        } else {
            reactSlickVar.initialSlide = 0;
        }
        if (!isPending) {
            checkKycData();
            logger.log(location.pathname, "quotationData SelectPlan.js", quotationData);
            logger.log(location.pathname, "react slick settings", reactSlickVar);
            setIsPendingLocal(false);
            scrollToMe.current.scrollIntoView({
                behavior: "instant",
                block: "start",
            });

            if (quotationData[0]?.productInfo?.autoRenewTag !== undefined) {
                logger.log(location.pathname, "auto renew tag restored: ", quotationData[0]?.productInfo?.autoRenewTag);
                setAutoRenewal(quotationData[0]?.productInfo?.autoRenewTag);
            }
            logger.log(location.pathname, "Plans: ", structuredPlanData);
        }
    }, [isPending]);

    return (
        <div>
            <div ref={scrollToMe}></div>
            {!isPendingLocal && (
                <div className="flex flex-col h-screen">
                    <div className="h-auto mb-auto" style={{ overflow: "auto" }}>
                        <Navigator title={"Select a Plan"} useRouterHistory={true} />
                        <Header currentStep="2" />
                        <div className="relative mb-12">
                            <img src={banner} alt="logo" className="w-full absolute" />
                        </div>
                        <div style={{ overflowX: "hidden" }}>
                            <SelectPlanCard
                                settings={reactSlickVar}
                                plans={structuredPlanData}
                                setAutoRenewal={setAutoRenewal}
                                showModalStateVb={showModalStateVb}
                                setShowModalStateVb={setShowModalStateVb}
                            />
                        </div>
                    </div>

                    <div className="bottom-0 z-50 bg-slate-100 border-t">
                        <div className="text-left rounded p-5">
                            <div className="flex">
                                <div className="flex-1 place-self-center font-bold text-md">
                                    <p className="">Auto Renewal</p>
                                </div>
                                <div className="place-self-center">
                                    <label className="switch">
                                        <input type="checkbox" checked={autoRenewal} onChange={() => setAutoRenewal(!autoRenewal)} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="px-5 pb-5">
                            <div className="mb-5">
                                <p className="text-sm px-5 text-center opacity-90 leading-relaxed">
                                    {/* Your premium will be auto-deducted from your GCash wallet according to your chosen period of coverage. */}
                                    Your next premium, according to your chosen period of coverage, will be auto-deducted from your GCash wallet.
                                </p>
                            </div>
                            <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => nextProcess()}>
                                Next
                            </button>
                        </div>
                    </div>
                    <ViewBreakdown showModalStateVb={showModalStateVb} setShowModalStateVb={setShowModalStateVb} />
                </div>
            )}
            {isPendingLocal && <Skeleton />}
        </div>
    );
};

export default SelectPlan;
