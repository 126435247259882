import { createContext, useContext, useState } from "react";
import logger from "utils/logger";
import { encrypt } from "services/decrypt";

const DataContext = createContext();

export function useDataContext() {
    return useContext(DataContext);
}

export function DataProvider({ children }) {
    const [sharedData, setSharedData] = useState([{ selectedSlide: null }, { policyPageTab: null }]);
    const [customerId, setCustomerId] = useState(null);
    const [kycData, setKycData] = useState(null);
    const [planData, setPlanData] = useState(null);
    const [structuredPlanData, setStructuredPlanData] = useState(null);
    const [selectedPlanData, setSelectedPlanData] = useState(null);
    const [quotationData, setQuotationData] = useState([]);
    const [policyDetailsData, setPolicyDetailsData] = useState(null);

    const updateSharedData = (newData) => {
        sharedData[0].selectedSlide = newData?.selectedSlide; // update the selected slide in the select plan page
        sharedData[1].policyPageTab = newData?.policyPageTab; // policy page selected tab
        localStorage.setItem("sharedData", encrypt(sharedData));
    };

    const updateCustomerId = (newData) => {
        // update the customerId. => This comes from Introduction page url params. To be used in register page for KYC Call
        setCustomerId(newData);
        localStorage.setItem("customerId", newData);
    };

    const updateKyc = (newData) => {
        setKycData(newData);
    };

    const updatePlanData = (newData) => {
        setPlanData(newData);
    };

    const updateStructuredPlanData = (newData) => {
        setStructuredPlanData(newData);
    };

    const updateSelectedPlanData = (newData) => {
        setSelectedPlanData(newData);
    };

    const updateQuotationData1 = (newData) => {
        // if (Object.keys(newData).length === 1 && Object.keys(newData).includes("customerInfo")) {
        // set customerId key in the quotationData
        if (Object.keys(newData).length === 1 && Object.keys(newData).includes("customerId")) {
            // console.log("customerInfo found", customerInfo);
            // let newQuotationData = [{ customerInfo: newData.customerInfo }];

            let newQuotationData = [{ customerId: newData.customerId }];
            setQuotationData(newQuotationData);
            logger.log("DataContext", "customerId found.");
            logger.log("DataContext", "updateQuotationData", newQuotationData);
        }

        // set customerInfo key in the quotationData
        if (Object.keys(newData).length === 1 && Object.keys(newData).includes("customerInfo")) {
            let newQuotationData = [
                {
                    ...quotationData[0],
                    // customerInfo: { emailAddress: kycData?.emailAddress },
                    ...newData,
                },
            ];
            setQuotationData(newQuotationData);
            console.log("customerInfo found");
            logger.log("DataContext", "updateQuotationData", newQuotationData);
        }

        // set productInfo key in the quotationData
        if (Object.keys(newData).length === 1 && Object.keys(newData).includes("productInfo")) {
            let newQuotationData = [
                {
                    ...quotationData[0],
                    productInfo: {
                        lineCd: newData.productInfo.lineCd,
                        sublineCd: newData.productInfo.sublineCd,
                        planCd: newData.productInfo.planCd,
                        freqCd: newData.productInfo.freqCd,
                        vrtlAcctNo: kycData?.selectedVrtlAcctNo?.vrtlAcctNo,
                    },
                },
            ];
            setQuotationData(newQuotationData);
            console.log("productInfo found");
            logger.log("DataContext", "updateQuotationData", newQuotationData);
        }
    };

    const updateQuotationData = (newData) => {
        // when passing customerId it can be singled pass [{customerId: ''}]
        // when passing customerInfo it must also pass the customerId [{customerId: ''}, {customerInfo: { 'something here' }}]
        // when passing productInfo it must pass all 3 customerId, customerInfo, productInfo  [{customerId: ''}, {customerInfo: { 'something here' }}, {productInfo: { 'something here' }}]
        const quotationDataLocal = [
            {
                ...newData[0],
                ...newData[1],
                productInfo: {
                    // ...newData[2]?.productInfo,
                    lineCd: newData[2]?.productInfo.lineCd,
                    sublineCd: newData[2]?.productInfo.sublineCd,
                    planCd: newData[2]?.productInfo.planCd,
                    freqCd: newData[2]?.productInfo.freqCd,
                    vrtlAcctNo: kycData?.selectedVrtlAcctNo?.vrtlAcctNo,
                },
            },
        ];
        setQuotationData(quotationDataLocal);
        logger.log("DataContext", "updateQuotationData", quotationDataLocal);
    };

    const updateQuotationDataFull = (newData) => {
        setQuotationData(newData);
    };

    const updatePolicyDetailsData = (newData) => {
        setPolicyDetailsData(newData);
    };

    return (
        <DataContext.Provider
            value={{
                sharedData,
                customerId,
                kycData,
                planData,
                structuredPlanData,
                selectedPlanData,
                quotationData,
                policyDetailsData,
                updateSharedData,
                updateCustomerId,
                updateKyc,
                updatePlanData,
                updateStructuredPlanData,
                updateSelectedPlanData,
                updateQuotationData,
                updatePolicyDetailsData,
                updateQuotationDataFull,
            }}>
            {children}
        </DataContext.Provider>
    );
}
