import annual from "assets/images/icons/Annual.png";
import monthly from "assets/images/icons/Monthly.png";
import quarter from "assets/images/icons/Quarterly.png";
import { Card } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";
import { numberUtil } from "utils/numberutil";
import { decrypt, restoreCryptoKey } from "services/decrypt";

const SelectPlanCard = ({ settings, plans, setAutoRenewal, showModalStateVb, setShowModalStateVb }) => {
    const { customerId, kycData, sharedData, selectedPlanData, updateSharedData, updateSelectedPlanData, updateQuotationData } = useDataContext();
    const location = useLocation();
    const sliderRef = useRef();
    const refs = useRef([]);
    const [currentSelection, setCurrentSelection] = useState(null);
    const { updateErrorData } = useNotifContext();

    const choosePlan = async (selected, modes, selectedSlide) => {
        logger.log(location.pathname, "plan card refs name", selected);
        setCurrentSelection(selected);
        updateSelectedPlanData(modes);
        updateSharedData({ selectedSlide: selectedSlide });
        // updateQuotationData({ productInfo: modes });

        await restoreCryptoKey();
        // If kycData in the Context is missing. Check if theres available in localStorage
        const kycDataLocal = kycData || JSON.parse(decrypt(localStorage.getItem("kycData")));
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        updateQuotationData([{ customerId: customerIdParams }, { customerInfo: { emailAddress: kycDataLocal?.emailAddress, ...kycDataLocal?.kycInputs } }, { productInfo: modes }]);

        if (modes.freqUnit) {
            setAutoRenewal(true);
        } else {
            setAutoRenewal(false);
        }
        logger.log(location.pathname, "selected plan", modes);
    };

    const navigate = useNavigate();
    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    const showBreakdown = () => {
        if (selectedPlanData) {
            setShowModalStateVb(!showModalStateVb);
        } else {
            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: "Please select your preferred coverage period below to view breakdown",
                redirectUrl: null,
            });
        }
    };

    useEffect(() => {
        if (selectedPlanData) {
            logger.log(location.pathname, "selected plan already exists.", selectedPlanData);
            setCurrentSelection(selectedPlanData.planName + selectedPlanData.planCd + selectedPlanData.freqCd);
        }
        if (sharedData.length) {
            if (sharedData[0].selectedSlide !== null) {
                logger.log(location.pathname, "slide re-shown using Context", sharedData);
                sliderRef.current.slickGoTo(sharedData[0].selectedSlide, true);
            } else if (selectedPlanData && localStorage.getItem("sharedData") !== null) {
                // NOTE: Re-show selected slide using localStorage but only when a selectedPlan is present
                const sharedDataLocal = JSON.parse(decrypt(localStorage.getItem("sharedData")));
                logger.log(location.pathname, "slide re-shown using localStorage", sharedDataLocal);
                sliderRef.current.slickGoTo(sharedDataLocal[0].selectedSlide, true);
            }
        }
    }, []);

    return (
        <div>
            {plans && (
                <div className="pt-10">
                    <Slider {...settings} className="mb-8" ref={sliderRef}>
                        {plans &&
                            plans.map((plans, index) => {
                                return (
                                    <div key={index}>
                                        <div className="p-5 mb-2">
                                            <Card className="full-width text-center">
                                                <h5 className="text-xl font-bold tracking-tight">
                                                    <p>{plans.planName.toUpperCase()}</p>
                                                </h5>
                                                <div className="text-lg mb-3">
                                                    <p>Coverage of up to</p>
                                                    <p className="font-bold text-md">PHP {numberUtil.formatNumber(plans.maxCov)}</p>
                                                    <span className="text-xs">plus card replacement and delivery</span>
                                                </div>
                                                <div hidden>
                                                    {/* <button className="text-sm text-blue-600" onClick={() => nextStep("/purchase/view_breakdown")}>
                                                        View Breakdown
                                                    </button> */}
                                                    <button
                                                        className="text-sm text-defaultblue hover:underline"
                                                        // onClick={() => setShowModalStateVb(!showModalStateVb)}
                                                        onClick={() => showBreakdown()}>
                                                        View Breakdown
                                                    </button>
                                                </div>
                                            </Card>
                                        </div>

                                        <div className="px-8">
                                            {plans.modes.map((modes) => {
                                                return (
                                                    <div
                                                        key={modes.planName + modes.planCd + modes.freqCd}
                                                        className={`py-5 px-8 border rounded mb-8
                      ${currentSelection === modes.planName + modes.planCd + modes.freqCd ? "bg-blue-100 border-blue-500" : "bg-gray-50 border-gray-300"}`}
                                                        ref={(element) => {
                                                            refs.current[modes.planName + modes.planCd + modes.freqCd] = element;
                                                        }}
                                                        onClick={() => choosePlan(modes.planName + modes.planCd + modes.freqCd, modes, index)}
                                                        style={{ cursor: "pointer" }}>
                                                        <div className="flex justify-between gap-7 relative">
                                                            {modes.freqUnit === "year" && <div className="freeShippingRibbon font-bold">Best Choice!</div>}
                                                            <div className="place-self-center bg-gray-100 rounded-full">
                                                                <img
                                                                    src={modes.freqUnit === "month" ? monthly : modes.freqUnit === "semi-annum" ? quarter : annual}
                                                                    alt="logo"
                                                                    className="p-2 bullet-logo"
                                                                />
                                                            </div>
                                                            <div className="w-full">
                                                                <p className="font-bold text-lg mb-1">{modes.freqDesc}</p>
                                                                <p className="text-xs mb-1 max-w-[80%]">{modes.remarks}</p>
                                                                {modes.freqUnit != "year" && (
                                                                    <p className="mb-1 text-sm">
                                                                        PHP {modes.totPrem} /{modes.freqUnit == "semi-annum" ? " 6 months " : " month "}
                                                                    </p>
                                                                )}
                                                                <p className="mb-1 text-sm">
                                                                    {modes.freqUnit === "year" && `PHP ${modes.totPrem} / year`}
                                                                    {/* {modes.freqUnit === "month" && `for as low as ${modes.totPrem * 12} / year`} */}
                                                                    {/* {modes.freqUnit === "semi-annum" && `for as low as ${modes.totPrem * 2} / year`} */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-5 text-center" style={{ display: currentSelection === modes.planName + modes.planCd + modes.freqCd ? "" : "none" }}>
                                                            <button className="text-sm text-blue-600 hover:underline" onClick={() => showBreakdown()}>
                                                                View Breakdown
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                    </Slider>
                </div>
            )}
        </div>
    );
};

export default SelectPlanCard;
