// import { HiX } from "react-icons/hi";
import { useDataContext } from "store/DataContext";
import { numberUtil } from "utils/numberutil";

const ViewBreakdown = ({ showModalStateVb, setShowModalStateVb }) => {
    const { selectedPlanData } = useDataContext();

    return (
        <div>
            {showModalStateVb && (
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="bg-black w-full h-full absolute opacity-30"></div>
                    <div className="relative w-full my-6 max-w-3xl mx-5">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                                <h3 className="text-xl font=semibold">Premium Breakdown</h3>
                                <button
                                    className="bg-transparent border-0 text-black float-right place-self-center"
                                    onClick={() => setShowModalStateVb(false)}
                                >
                                    {/* <HiX className="h-6 w-6 " /> */}
                                </button>
                            </div>
                            <div className="relative p-6 flex-auto gap-5">
                                <div className="">
                                    <div className="flex justify-between mb-5 font-bold">
                                        <div className="text-md place-self-center">{selectedPlanData && selectedPlanData?.freqDesc} Premium</div>
                                        <div className="text-blue-600 place-self-center">
                                            {/* <button className="text-md text-blue-600 hover:underline">What is this?</button> */}
                                        </div>
                                    </div>
                                    <div className="flex text-sm justify-between mb-2 opacity-90">
                                        <div>Card Protect Premium</div>
                                        <div className="text-sm">PHP {selectedPlanData && selectedPlanData?.premAmt}</div>
                                    </div>
                                    <div className="flex text-sm justify-between mb-2 opacity-90">
                                        <div>Documentary Stamp Tax</div>
                                        <div className="text-sm">{selectedPlanData && numberUtil.formatNumber(selectedPlanData?.dstAmt)}</div>
                                    </div>
                                    <div className="flex text-sm justify-between mb-2 opacity-90">
                                        <div>Value Added Tax</div>
                                        <div className="">{selectedPlanData && numberUtil.formatNumber(selectedPlanData?.vatAmt)}</div>
                                    </div>
                                    <div className="flex text-sm justify-between mb-2 pb-3 border-b opacity-90">
                                        <div>Local Gov't. Tax</div>
                                        <div className="">{selectedPlanData && numberUtil.formatNumber(selectedPlanData?.lgtAmt)}</div>
                                    </div>
                                    <div className="flex justify-between font-bold">
                                        <div className="place-self-center">Total Amount Due</div>
                                        <div className="text-lg place-self-center">
                                            {selectedPlanData && "PHP " + numberUtil.formatNumber(selectedPlanData?.totPrem)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    type="button"
                                    className="text-white w-full bg-defaultblue hover:bg-blue-800 hover:border-blue-800 border-defaultblue border-4 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-full text-md px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={() => setShowModalStateVb(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewBreakdown;
