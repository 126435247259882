// import arrow_right from "assets/images/arrow-right.png";
// import needhelpbanner from "assets/images/help-banner.png";
import needhelpbanner from "assets/images/banners/Need-help.png";
import Navigator from "components/Navigator";
import { Accordion } from "flowbite-react";
// import { HiDuplicate } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { useDataContext } from "store/DataContext";

const NeedHelp = () => {
    const { customerId } = useDataContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const customerIdParams = customerId !== null ? customerId : searchParams.get("customerId");
    const openDefaultBrowser = (link) => {
        // window.open(link, "_system");
        window.location.assign(link, "_system");
    };

    return (
        <div>
            <Navigator title={"Help"} useRouterHistory={true} />

            <div className="shadow-md">
                <img src={needhelpbanner} alt="logo" />
            </div>
            <div>
                <Accordion className="bg-white border-0">
                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 text-blue-950">Contact Us</Accordion.Title>
                        <Accordion.Content className="bg-slate-100">
                            <p className="mb-2 text-sm text-gray-500 leading-relaxed tracking-tighter">
                                To report a cyber incident, please file a ticket via GCash Help Center.
                                <br />
                                <br />
                                For assistance in addressing, preventing and mitigating cyber attacks or suspected cyber events, call BPI MS Cyber
                                Insurance Hotline at <span className="underline"> +632 8840-9444.</span>
                                <br />
                            </p>
                            <p className="mb-2 text-sm text-gray-500 leading-relaxed tracking-tighter">
                                To check on the status of your claims, please email <br />
                                <a href="mailto: BPIMS_Non-Motor_Claims@bpi.com.ph." className="hover:underline cursor-pointer">
                                    <span className="text-blue-600">BPIMS_Non-Motor_Claims@bpi.com.ph.</span>
                                </a>
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 text-blue-950">FAQs</Accordion.Title>
                        <Accordion.Content className="bg-slate-100 ">
                            <p className="mb-2 text-sm text-gray-500 leading-relaxed tracking-tighter"></p>
                            <a
                                className="text-blue-600 font-bold hover:underline cursor-pointer"
                                href={`/intro/faqs/?customerId=${customerIdParams}`}
                                // onClick={() => openDefaultBrowser("/intro/faqs/?customerId=" + customerIdParams)}
                            >
                                Go to FAQs Page
                            </a>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 text-blue-950">Terms & Conditions</Accordion.Title>
                        <Accordion.Content className="bg-slate-100 ">
                            {/* <p className="mb-2 text-sm text-gray-500 leading-relaxed tracking-tighter">
                                GCash Card Protect is a personal cyber insurance plan exclusive to GCash VISA Cardholders, and supplements and
                                complements the chargeback
                            </p> */}
                            <a
                                className="text-blue-600 font-bold hover:underline cursor-pointer"
                                onClick={() => openDefaultBrowser("https://bpims.com/mp-gcppci/")}
                            >
                                https://bpims.com/mp-gcppci/
                            </a>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 text-blue-950">Privacy Policy</Accordion.Title>
                        <Accordion.Content className="bg-slate-100 ">
                            <a
                                className="text-blue-600 font-bold hover:underline cursor-pointer"
                                onClick={() => openDefaultBrowser("https://bpims.com/privacy-policy/")}
                            >
                                https://bpims.com/privacy-policy/
                            </a>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 text-blue-950">How do I make a Claim?</Accordion.Title>
                        <Accordion.Content className="bg-slate-100 ">
                            <div className="mb-5 font-bold text-md opacity-90 leading-relaxed">
                                Call the BPI MS Personal Cyber Insurance Hotline at (632) 8840-9444 to file a claim.
                            </div>
                            <div className="mb-5">
                                <a
                                    href="tel: 632-8840-9444"
                                    className="flex flex-grid justify-center  max-w-lg p-6 text-white rounded-full shadow-md bg-blue-700 hover:bg-blue-800"
                                >
                                    <p className="text-white font-bold">Call the Hotline</p>
                                </a>
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            </div>
        </div>
    );
};

export default NeedHelp;
