import banner from "assets/images/banners/GCash-Card-Protect-Banner.png";
import helplogo from "assets/images/need-help-logo.png";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import { Card } from "flowbite-react";
import useFetchRedirectCongrats from "hooks/useFetchRedirectCongrats";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import { useDataContext } from "store/DataContext";
import logger from "utils/logger";
import { cards, tabs } from "utils/variables/introductionVar";
import { reactSlickVar } from "utils/variables/reactSlickVar";
import IntroductionCard from "./Card";

const Introduction = () => {
    const { customerId, updateCustomerId, updateSelectedPlanData } = useDataContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const refs = useRef([]);
    const slider = useRef();
    const [oldTab, setOldTab] = useState(0);
    const [gcashResParams, setGcashResParams] = useState(null);
    const { isPending } = useFetchRedirectCongrats(gcashResParams);

    reactSlickVar.dots = true;
    reactSlickVar.adaptiveHeight = true;
    const settings = {
        ...reactSlickVar,
        beforeChange: (current, next) => {
            if (oldTab != null) {
                refs.current[oldTab].classList.remove("border-defaultblue");
                refs.current[oldTab].classList.add("border-white");
                refs.current[oldTab].classList.remove("text-defaultblue");
                refs.current[oldTab].classList.add("text-gray-400");
            }
            refs.current[next].classList.add("border-defaultblue");
            refs.current[next].classList.remove("border-white");
            refs.current[next].classList.add("text-defaultblue");
            refs.current[next].classList.remove("text-gray-400");
            setOldTab(next);
        },
    };

    const navigate = useNavigate();
    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    useEffect(() => {
        if (searchParams.get("customerId") !== null) updateCustomerId(searchParams.get("customerId"));
        if (searchParams.get("params")) setGcashResParams(searchParams);
        updateSelectedPlanData(null);
        logger.log(location.pathname, "", searchParams.get("customerId"));
    }, []);

    return (
        <div>
            {!isPending && (
                <div className="bg-slate-100">
                    <Navigator title={"GCash x BPI MS"} ifBacked={undefined} />
                    <div>
                        <img src={banner} alt="banner" className="w-full" />
                    </div>

                    <div className="flex justify-evenly">
                        {tabs &&
                            tabs.map((tab, tabindex) => {
                                return (
                                    <button
                                        className={`tab-title font-bold text-center py-3 text-md border-b-2 hover:cursor-pointer ${tab.id == 0 ? "border-defaultblue text-defaultblue" : "border-white text-gray-400"} ${
                                            tab.id == 2 ? "w-1/2" : "w-1/3"
                                        }`}
                                        key={tabindex}
                                        ref={(element) => {
                                            refs.current[tab.id] = element;
                                        }}
                                        onClick={(e) => {
                                            slider.current.slickGoTo(tabindex);
                                        }}>
                                        {tab.title}
                                    </button>
                                );
                            })}
                    </div>

                    <Slider ref={slider} {...settings} className="mb-8">
                        {cards &&
                            cards.map((card, cardindex) => {
                                return (
                                    <div key={cardindex}>
                                        <IntroductionCard cardTitle={card.catchPhrase} icon={card.icon} currentindex={cardindex} benefits={card.benefits} />
                                    </div>
                                );
                            })}
                    </Slider>

                    <div className="p-5">
                        <Card className="full-width text-center rounded-xl">
                            <div className="text-left rounded">
                                <div className="flex justify-center gap-5">
                                    <div className="place-self-center">
                                        <img src={helplogo} alt="need help logo" width="100px" />
                                    </div>
                                    <div className="leading-normal">
                                        <p className="font-bold text-md mb-2">Have more questions?</p>
                                        <span className="opacity-90 text-sm">View our complete product details below. </span>
                                        <button onClick={() => nextStep("faqs")} className="text-sm text-defaultblue font-bold hover:underline">
                                            <span> Learn more</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="px-5">
                        <div className="bg-slate-100 pb-10">
                            <button
                                className="bg-defaultblue rounded-full w-full p-5 text-white font-bold tracking-widest hover:bg-blue-800"
                                onClick={() => {
                                    nextStep("/purchase/register");
                                }}>
                                Renew Now
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isPending && <Skeleton />}
        </div>
    );
};

export default Introduction;
