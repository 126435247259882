import logger from "./logger";

const access = {
    prodAccess: () => {
        // NOTE: Block Access except GCash
        const forTesting = true;
        const isGCash = navigator.userAgent.includes("GCash");
        if (
            window.location.hostname === "https://gcyber.bpimsapps.com" &&
            window.location.pathname !== "/access_denied" &&
            !(isGCash || forTesting)
        ) {
            window.location.replace("/access_denied");
        }
        logger.log("App.js", "userAgent", navigator.userAgent);
    },
};

export default access;
