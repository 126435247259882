import Navigator from "components/Navigator";
import { Accordion } from "flowbite-react";
import { useEffect, useRef } from "react";

const Faqs = () => {
    const scrollToMe = useRef();

    const insuranceItems = [
        "Card Replacement to reimburse the cost to replace the physical card against accidental damage, accidental loss, and theft (excluding mysterious disappearance). Lost or stolen shall mean having been inadvertently lost or having been stolen by a third party without the owner's assistance, consent or co-operation.",
        "Delivery Fee for replacement of lost card.",
        "Fraudulent/Unauthorized Transaction - reimburses for financial loss arising directly from a cyber event, which the Insured first discovered during the policy period.",
    ];

    const notCoveredItems = [
        "Losses that are covered under the Visa Cashback program",
        "Abnormal Conditions/ State of Emergency / Mass Cyber-Attack",
        "Business Activities - any loss based upon, arising from or as a consequence of any activities carried out by the Insured for trade, business or professional purposes, including any loss connected to an account with a financial institution that is used for trade, business or professional purposes.",
        "Confidence Scams - any loss based upon, arising from or as a consequence of a confidence trick involving feigned intentions towards the Insured, such as romantic intentions, investments, contracts, loans and the like, gaining the Insured’s confidence or affections, and then using that goodwill to commit fraud.",
        "Currency - any loss involving unregulated digital currency of any kind, unregulated virtual currency of any kind or unregulated cryptocurrency of any kind.",
        "Dishonest Acts",
        "Indirect or Consequential Loss",
        "Infrastructure Failure - any loss based upon, arising from or as a consequence of any outage in or disruption of electrical power or telecommunications services operated by a third-party service provider.",
        "Lost or Stolen Plastic Cards unless specifically covered",
        "Nuclear Risks",
        "Prior Matters - any loss based upon, arising from or as a consequence of any fact, circumstance, act, threat, or event which the Insured was aware of prior to the inception date of this policy.",
        "Property Damage",
        "Terrorism",
        "Voluntary Disclosure - any loss based upon, arising from or as a consequence of voluntary disclosure by the Insured of any code or other security information to someone who subsequently commits or colludes with the Insured in an identity theft, cyber event, non-delivery, accidental damage or theft.",
        "War, Invasion and Civil Commotion",
        "Wear and Tear",
        "Police Report - any loss based upon, arising out of or a consequence of nonreporting of the identity theft, cyber event, or theft to the police and/or the Insured do not have a police reference number.",
        "Corporate Cyber Liability Exclusion - any losses related to corporate cyber liability or arising from a commercial cyber-attack and any losses where a legal corporate entity of any sort is the subject of a cyber-attack. Legal corporate entities here are in reference to but not limited to NGOs or any registered or unregistered business entity.",
        "Infectious / Communicable Disease Exclusion",
    ];

    useEffect(() => {
        scrollToMe.current.scrollIntoView({
            behavior: "instant",
            block: "start",
        });
    }, []);

    return (
        <div className="bg-white h-auto">
            <div ref={scrollToMe}></div>
            <Navigator title={"FAQs"} useRouterHistory={true} />
            <div className="p-5 min-h-screen">
                <div className="mb-5 font-black text-xl">
                    <span>Frequently Asked Questions</span>
                </div>

                <Accordion className="bg-white border-0">
                    {/* style={{ textAlign: "justify", textJustify: "interWord" }} */}
                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 focus:ring-white hover:bg-white text-blue-950 text-[16px]">
                            What are the Insurance benefits under the GCash VISA Card?
                        </Accordion.Title>
                        <Accordion.Content className="bg-white text-gray-500 tracking-wide leading-relaxed text-[14px]">
                            <div>
                                <p className="mb-4">GCash Card Protect offers the following benefits:</p>
                                <div className="ml-5 flex flex-col gap-5 mb-5 leading-relaxed">
                                    {insuranceItems.map((item, index) => {
                                        return (
                                            <li className="mb-2" key={index}>
                                                {item}
                                            </li>
                                        );
                                    })}
                                </div>
                                <p className="leading-relaxed">
                                    Note that Card Replacement and Delivery Fee is not applicable to GCash VISA Cards that were subject to cancellation or permanent blocking as and if requested by the
                                    customer.
                                </p>
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 focus:ring-white hover:bg-white text-blue-950 text-[16px]">What is a cyber event?</Accordion.Title>
                        <Accordion.Content className="bg-white text-gray-500 tracking-wide leading-relaxed text-[14px]">
                            <div>
                                <p className="mb-4 ">A Cyber event means: </p>

                                <div className="ml-5 flex flex-col gap-5 mb-5 leading-relaxed">
                                    <li>the fraudulent electronic transfer of funds or property from your personal account with a financial institution by:</li>
                                    <div className="ml-10 flex flex-col gap-5 leading-relaxed">
                                        <li>a third party; or </li>
                                        <li>
                                            the financial institution acting in good faith upon a fraudulent incoming electronic communication purporting to have been sent but which was not sent by
                                            you;
                                        </li>
                                    </div>
                                    <li>
                                        you acting in good faith upon any fraudulent incoming electronic communication request and transferring funds or property from your personal account with a
                                        financial institution, to the account of a third party;
                                    </li>
                                    <li>fraudulent unauthorized use of or electronic transfer of funds stored in your personal digital wallet held with an online merchant; or</li>
                                    <li>the fraudulent unauthorized online purchases by a third party that are charged to your plastic card.</li>
                                    <li>
                                        fraudulent charges made by a third party against your lost or stolen plastic card, incurred only within twelve (12) hours prior to your reporting of the loss to
                                        the issuers of your plastic card (where applicable) and/or the financial institution where your personal account is held.
                                    </li>
                                </div>
                                <li className="leading-relaxed">
                                    Note that Card Replacement and Delivery Fee is not applicable to GCash VISA Cards that were subject to cancellation or permanent blocking as and if requested by the
                                    customer.
                                </li>
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 focus:ring-white hover:bg-white text-blue-950 text-[16px]">What is not covered under GCash Card Protect?</Accordion.Title>
                        <Accordion.Content className="bg-white-50 text-gray-500 tracking-wide leading-relaxed text-[14px]">
                            {notCoveredItems.map((notcov, index) => {
                                return (
                                    <p className="mb-5 leading-relaxed" key={index}>
                                        {String.fromCharCode(97 + index)}. {notcov}
                                    </p>
                                );
                            })}
                        </Accordion.Content>
                    </Accordion.Panel>

                    <Accordion.Panel>
                        <Accordion.Title className="bg-white focus:ring-0 focus:ring-white hover:bg-white text-blue-950 text-[16px]">
                            What documents do I need to submit to enable me to claim?
                        </Accordion.Title>
                        <Accordion.Content className="bg-white-50 text-gray-500 tracking-wide leading-loose text-[14px]">
                            <div>
                                <p className="mb-4">For Card Replacement/Delivery Cost:</p>
                                <div className="ml-5 flex flex-col mb-5 leading-relaxed">
                                    <p>a. Claim Form</p>
                                    <p>b. Valid ID of the subscriber</p>
                                    <p>c. Proof of request for card replacement</p>
                                </div>
                            </div>
                            <div>
                                <p className="mb-4">For Unauthorized Charges:</p>

                                <div className="ml-5 flex flex-col mb-5 leading-relaxed">
                                    <p>a. Claim Form</p>
                                    <p>b. Police Report</p>
                                    <p>c. Proof of request for card replacement</p>
                                </div>
                            </div>
                        </Accordion.Content>
                    </Accordion.Panel>
                </Accordion>
            </div>
        </div>
    );
};

export default Faqs;