import { useEffect, useState } from "react";
import { encrypt } from "services/decrypt";
import { decryptPolicyGetResponse } from "services/policy-get";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";
import { dateUtil } from "utils/dateutil";

const useFetchPolicyDetails = (quoteId) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { updatePolicyDetailsData, customerId } = useDataContext();
    const { updateErrorData } = useNotifContext();

    async function policyGet(payload) {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        await decryptPolicyGetResponse(payload)
            .then((data) => {
                if (data?.length) {
                    if (data[0]?.status === "SUCCESS") {
                        const policyData = data[1]?.body;
                        updatePolicyDetailsData(policyData);
                        localStorage.setItem("params", btoa(encrypt(payload)));
                        localStorage.setItem("ndd", dateUtil.formatDateToReadable(policyData?.expiryDate));
                        logger.log("useFetchPolicyDetails", "policy/get response", policyData);

                        setData(data[1]?.body);
                        setIsPending(false);
                    } else {
                        logger.log("useFetchPolicyDetails", "Error", data);
                        throw new Error(data[0]?.status || "Something went wrong");
                    }
                } else {
                    throw new Error("Something went wrong.");
                }
            })
            .catch((error) => {
                logger.log("useFetchPolicyDetails", "Catched Error", error);
                if (error.message === "TIMEOUT REACHED") {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Application Timeout.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                } else {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Something went wrong.",
                        redirectUrl: "/intro/?customerId=" + customerIdParams,
                    });
                }
            });
    }

    useEffect(() => {
        if (quoteId) {
            const payload = { quoteId: quoteId?.quoteId, ecId: quoteId?.ecId ?? null };
            logger.log("useFetchPolicyDetails", "policy/get payload", payload);
            policyGet(payload);
        }
    }, [quoteId]);

    return { data, isPending };
};

export default useFetchPolicyDetails;
