import { useEffect, useState } from "react";
import { dateUtil } from "utils/dateutil";
import logger from "utils/logger";

const useSetNextDueDate = (frequency) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);

    function nextDueDate(frequency) {
        let date = dateUtil.dateNow();
        let dateTwo = dateUtil.dateNow();
        if (frequency.expDate !== null) {
            date = new Date(frequency.expDate);
            dateTwo = new Date(frequency.expDate);
        }
        // add 1 day only if the policy has no available expiry date.
        if(frequency.expDate === null){
            const datePlusOne = date.getDate() + 1;
            date.setDate(datePlusOne);
        }
        logger.log("useSetNextDueDate", "frequency data", frequency);

        // let dateMinusOne
        if (frequency.freqUnit === "month") {
            date.setMonth(dateTwo.getMonth() + 1);
            logger.log("useSetNextDueDate", "1 month", date);
        } else if (frequency.freqUnit === "semi-annum") {
            date.setMonth(dateTwo.getMonth() + 6);
            logger.log("useSetNextDueDate", "6 month", date);
        } else if (frequency.freqUnit === "year") {
            date.setFullYear(dateTwo.getFullYear() + 1);
            logger.log("useSetNextDueDate", "1 year", date);
        } else {
            logger.log("useSetNextDueDate", "added nothing", null);
        }

        setData(
            date.toLocaleDateString("en-us", {
                day: "numeric",
                year: "numeric",
                month: "short",
            })
        );
        setIsPending(false);
    }

    useEffect(() => {
        if (frequency) {
            nextDueDate(frequency);
        }
    }, [frequency]);

    return { data, isPending };
};

export default useSetNextDueDate;
