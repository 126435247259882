const tabs = [
    {
        id: 0,
        title: "Benefits",
    },
    {
        id: 1,
        title: "Covered",
    },
    {
        id: 2,
        title: "Not Covered",
    },
    {
        id: 3,
        title: "Claims",
    },
];

const cards = [
    {
        parent: "Benefits",
        catchPhrase: "What's in it for you?",
        icon: "What-is-in-it-for-you.png",
        benefits: [
            {
                title: "Cash Benefits",
                icon: "Cash-Benefits.png",
                content: "Get reimbursed for fraudulent transactions on your card.",
            },
            {
                title: "24x7 Cyber Experts",
                icon: "24x7-cyber-experts.png",
                content: "Access Cyber Experts anytime you suspect fraud and need help.",
            },
            {
                title: "Coverage Periods",
                icon: "Coverage-Periods.png",
                content: "Choose how long you wish to be covered.",
            },
        ],
    },
    {
        parent: "Covered",
        catchPhrase: "What's Covered?",
        icon: "Category-what-is-covered.png",
        benefits: [
            {
                title: "Unauthorized Transactions",
                icon: "Unauthorized-transactions.png",
                content: "Reimburse up to P20,000 in fraudulent transactions.",
            },
            {
                title: "Lost, Damaged or Stolen",
                icon: "Card-replacement.png",
                content: "Free card replacement in case of accidental loss, damage or theft.",
            },
            {
                title: "Card Delivery Fees",
                icon: "Card-delivery-fees.png",
                content: "Receive reimbursements for delivery fees up to P225.",
            },
        ],
    },
    {
        parent: "Not Covered",
        catchPhrase: "What's not Covered?",
        icon: "Category-what-is-not-covered.png",
        benefits: [
            {
                title: "Confidence Scams",
                icon: "Confidence-scams.png",
                content: "Fraud resulting from confidence tricks.",
            },
            {
                title: "Voluntary Disclosure",
                icon: "Voluntary-Disclosure.png",
                content: "Losses resulting from customer sharing OTPs and security codes.",
            },
            {
                title: "Losses Covered by GCash",
                icon: "Covered by GCash-Visa.png",
                content: "Losses with claims already paid for by the GCash chargeback program.",
            },
        ],
    },
    {
        parent: "Claims",
        catchPhrase: "How to Claim",
        icon: "How-to-claim.png",
        benefits: [
            {
                title: "File a Ticket",
                icon: "File-a-ticket.png",
                content: "Report covered incident to GCash within 3 days of discovery.",
            },
            {
                title: "Claims Investigation",
                icon: "Claims-investigation.png",
                content: "Provide information and documents required.",
            },
            {
                title: "Claim Check Payment",
                icon: "Check-payment.png",
                content: "Once approved, collect claims pay out from BPI MS.",
            },
        ],
    },
];

const headerContent = [
    {
        step: 1, // step 1
        title: "Let's get started",
        subTitle: "Provide your GCash Virtual Account Number found at the back of your card or in the app. More details",
    },
    {
        step: 2, // step 2
        title: "Review your Information ",
        subTitle: "Please fill out the form below with correct details.",
    },
    {
        step: 3, // step 3
        title: "Protection Plan and Period",
        subTitle: "Choose your period of coverage.",
    },
    {
        step: 4, // step 4
        title: "Summary",
        subTitle: "Kindly review your information below before proceeding.",
    },
    {
        step: 5, // review coverage
        title: "Coverage Details",
        subTitle: "Please review the details of your coverage.",
    },
];

export { cards, headerContent, tabs };
