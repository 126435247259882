import likelogo from "assets/images/like-logo.png";

const CancelSuccess = () => {
    return (
        <div>
            <div className="flex flex-col h-screen bg-slate-50">
                <div className="mb-auto">
                    <div className="flex justify-center mt-12">
                        <div className="bg-blue-50 rounded-full p-3">
                            <img src={likelogo} alt="Like logo" />
                        </div>
                    </div>

                    <div className="px-5 mt-8 text-center text-xl font-bold text-blue-500">
                        <p>You have cancelled your policy</p>
                    </div>

                    <div className="px-12 mt-5 mb-6 text-center text-md text-gray-500 leading-relaxed">
                        <p>Should you need any assistance or have any inquiries, please contact partner@email.com</p>
                    </div>

                    <div className="p-5">
                        <div className="px-3 mt-5 mb-3">
                            <div className="border-y-2 border-dashed py-10">
                                <div className="flex justify-center gap-5">
                                    <p className="text-md font-bold text-gray-500 place-self-center">Reference no.</p>
                                    <p className="text-lg font-bold place-self-center text-blue-500">165A25912345</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-6">
                    <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold">Ok</button>
                </div>
            </div>
        </div>
    );
};

export default CancelSuccess;
