import { useEffect, useState } from "react";
import { decryptData } from "services/data-get";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";

const useFetchPlanData = () => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { customerId, updatePlanData, updateStructuredPlanData } = useDataContext();
    const { updateErrorData } = useNotifContext();

    const filterAppDataAsync = (data) => {
        return new Promise((resolve) => {
            // join the objects with equal identifiers
            const joinedArray = data.BMM194.map((obj194) => ({
                ...obj194,
                ...data.BMM193.find((obj193) => obj193.freqCd === obj194.freqCd),
                ...data.BMM038.find(
                    (obj038) => obj038.planCd === obj194.planCd && obj038.lineCd === obj194.lineCd && obj038.sublineCd === obj194.sublineCd
                ),
                ...data.BMM030.find((obj030) => obj030.lineCd === obj194.lineCd && obj030.sublineCd === obj194.sublineCd),
                ...data.BMM030.find((obj029) => obj029.lineCd === obj194.lineCd),
            }));

            const groupedArray = joinedArray.reduce((result, item) => {
                // Use the 'category' // basic elite prime field as the key
                const key = item.planName;
                // If the key doesn't exist in the result, create a new array
                if (!result[key]) {
                    result[key] = [];
                }
                // Push the item into the array associated with the key
                result[key].push(item);
                return result;
            }, {});

            // Convert the grouped data back into an array of objects with the desired structure
            const desiredArray = Object.keys(groupedArray).map((key, index) => ({
                planCd: groupedArray[key][index].planCd,
                freqCd: groupedArray[key][index].freqCd,
                planName: groupedArray[key][index].planName,
                maxCov: groupedArray[key][index].maxCov,
                modes: groupedArray[key],
                id: index,
            }));

            setTimeout(() => {
                resolve(desiredArray);
            }, 1000);
        });
    };

    async function fetchData() {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        try {
            const data = await decryptData();
            await filterAppDataAsync(data)
                .then((result) => {
                    if (result?.length) {
                        updatePlanData(data);
                        updateStructuredPlanData(result);
                        setData(result);
                        setIsPending(false);
                    } else {
                        throw new Error("Something went wrong.");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    throw new Error("Something went wrong.");
                });
        } catch (error) {
            setIsPending(true);
            updateErrorData({
                showModal: true,
                modalType: "error",
                modalMessage: "Something went wrong.",
                redirectUrl: "/intro/?customerId=" + customerIdParams,
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return { data, isPending };
};

export default useFetchPlanData;
