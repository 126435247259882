import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
// Intro Screens
import Faqs from "views/intro/Faqs";
import Introduction from "views/intro/Index";

// Purchase Screens
import CoverageDetails from "views/purchase/CoverageDetails";
import Register from "views/purchase/Register";
import Review from "views/purchase/Review";
import SelectPlan from "views/purchase/SelectPlan";
import Success from "views/purchase/Success";
import Summary from "views/purchase/Summary";

// Mangement Screens
import AutoRenewal from "views/mgt/AutoRenewal";
import CancelPolicy from "views/mgt/CancelPolicy";
import CancelSuccess from "views/mgt/CancelSuccess";
import Index from "views/mgt/Index";
import MakeAClaim from "views/mgt/MakeAClaim";
import NeedHelp from "views/mgt/NeedHelp";
import ViewContract from "views/mgt/ViewContract";

// 404 Page
import NotFound from "components/NotFound";

import { DataProvider } from "store/DataContext";
import { NotifProvider } from "store/NotifContext";

import disableAccess from "utils/disableAccess";
import AccessDenied from "components/AccessDenied";
import ErrorPage from "components/ErrorPage";
import MaintenancePage from "components/MaintenancePage";
import Layout from "views/Layout";

const routesFromElements = createRoutesFromElements(
    <Route element={<Layout />}>
        {/* <Route index element={<HomePage />} /> */}

        {/* <Route path="/" element={<Navigate to="/intro" replace />} errorElement={<ErrorPage />} /> */}

        {/* <Route
            path="/"
            element={<Navigate to={`intro/?customerId=${localStorage.getItem("customerId")}`} replace />}
            errorElement={<ErrorPage />}
        /> */}

        <Route path="/" element={<Introduction />} errorElement={<ErrorPage />} />

        <Route path="/intro" element={<Introduction />} errorElement={<ErrorPage />} />

        <Route path="/faqs" element={<Navigate to="/intro/faqs" replace />} errorElement={<ErrorPage />} />

        <Route path="/intro/faqs" element={<Faqs />} errorElement={<ErrorPage />} />

        <Route path="/purchase/register" element={<Register />} errorElement={<ErrorPage />} />

        <Route path="/purchase/review" element={<Review />} errorElement={<ErrorPage />} />

        <Route path="/purchase/select_plan" element={<SelectPlan />} errorElement={<ErrorPage />} />

        <Route path="/purchase/summary" element={<Summary />} errorElement={<ErrorPage />} />

        <Route path="/purchase/coverage" element={<CoverageDetails />} errorElement={<ErrorPage />} />

        <Route path="/purchase/success" element={<Success />} errorElement={<ErrorPage />} />

        <Route path="/management" element={<Index />} errorElement={<ErrorPage />} />

        <Route path="/management/view_contract" element={<ViewContract />} errorElement={<ErrorPage />} />

        <Route path="/management/need_help" element={<NeedHelp />} errorElement={<ErrorPage />} />

        <Route path="/management/auto_renewal" element={<AutoRenewal />} errorElement={<ErrorPage />} />

        <Route path="/management/make_claim" element={<MakeAClaim />} errorElement={<ErrorPage />} />

        <Route path="/management/cancel_policy" element={<CancelPolicy />} errorElement={<ErrorPage />} />

        <Route path="/management/cancel_success" element={<CancelSuccess />} errorElement={<ErrorPage />} />

        <Route path="/maintenance_page" element={<MaintenancePage />} errorElement={<ErrorPage />} />

        <Route path="/access_denied" element={<AccessDenied />} errorElement={<ErrorPage />} />

        <Route path="/error_page" element={<ErrorPage />} errorElement={<ErrorPage />} />

        <Route path="*" element={<NotFound />} />
    </Route>
);

const router = createBrowserRouter(routesFromElements);

function App() {
    disableAccess.prodAccess();
    return (
        <div
            className="container mx-auto leading-none text-blue-950"
            style={{
                // overflowY: "auto",
                overflowX: "hidden",
                height: "100vh",
                maxWidth: "500px",
            }}
        >
            <DataProvider>
                <NotifProvider>
                    <RouterProvider router={router} />
                </NotifProvider>
            </DataProvider>
        </div>
    );
}

export default App;
