import { useEffect, useState } from "react";
import { HiOutlineQuestionMarkCircle, HiOutlineCheckCircle } from "react-icons/hi";

const ConfirmationPrompt = ({ showModal, modalMessage, onConfirm, onCancel, modalType = "confirmation", confirmBtnText = "Confirm", cancelBtn = true, }) => {
    const [showModalState, setShowModalState] = useState(false);
    const [modalMessageState, setModalMessage] = useState(false);

    const handleConfirm = () => {
        onConfirm();
        setShowModalState(false);
    };

    const handleCancel = () => {
        onCancel();
        setShowModalState(false);
    };

    useEffect(() => {
        setShowModalState(showModal);
        setModalMessage(modalMessage);
    }, [showModal]);

    return (
        <div className="">
            {showModalState && (
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="bg-black w-full h-full absolute opacity-40"></div>
                    <div className="relative w-auto my-6 max-w-3xl mx-5">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="relative p-6 flex-auto">
                                <div className="px-6 py-12">
                                    {modalType === "confirmation" && <HiOutlineQuestionMarkCircle className="mx-auto mb-4 h-20 w-20 text-blue-500" />}
                                    {modalType === "info_success" && <HiOutlineCheckCircle className="mx-auto mb-4 h-20 w-20 text-green-500" />}
                                    <p className="text-center text-lg font-bold leading-relaxed text-blue-950">{modalMessageState && modalMessageState}</p>
                                    {modalType === "confirmation" && <p className="text-center text-sm leading-relaxed text-blue-950 mt-5">Choose Confirm to Proceed</p>}
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    type="button"
                                    className="text-white w-full mb-2 bg-defaultblue border-defaultblue border-4 hover:bg-blue-800 hover:border-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-full text-md px-5 py-2.5 text-center"
                                    onClick={handleConfirm}>
                                    {confirmBtnText}
                                </button>
                                <button
                                    type="button"
                                    // className={`text-white w-full ${cancelBtn ? 'bg-amber-500 hover:bg-amber-600' : 'bg-red-700 hover:bg-red-800 '}  focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center`}
                                    className={`text-defaultblue w-full border-defaultblue border-4 focus:ring-4 focus:outline-none font-bold rounded-full text-md px-5 py-2.5 text-center`}
                                    onClick={handleCancel}>
                                    {cancelBtn && "Cancel"}
                                    {!cancelBtn && "Cancel"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConfirmationPrompt;
