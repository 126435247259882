import { HiXCircle } from "react-icons/hi";

const ErrorPage = () => {
    return (
        <div>
            <div className="flex flex-col h-screen bg-slate-50">
                <div className="mb-auto">
                    <div className="flex justify-center mt-16 mb-12">
                        <div className="bg-red-50 rounded-full p-3">
                            {/* <div className="p-3"> */}
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-48 h-48 text-red-800"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                                />
                            </svg> */}
                            <HiXCircle className="text-red-500 w-48 h-48" />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex mt-10 px-10 text-3xl font-bold text-red-500 text-center">Access Denied!</div>
                    </div>
                    <div className="px-12 mt-5 mb-6 text-center text-lg text-gray-500 leading-relaxed">
                        <p>Thank you for your interest in our product.</p>
                        <span className="text-sm">
                            <br /> You can visit us on GCash app to avail your insurance.
                        </span>
                        <span className="text-sm">
                            {" "}
                            For immediate protection needs, you may contact us at insure@bpims.com or call (02) 8840-9000.
                        </span>
                    </div>
                </div>

                <div className="p-6">
                    {/* <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => nextProcess()}>
                        Reload
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
