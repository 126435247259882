import { buildCsrfToken, decrypt, encryptedParamsDynamic, testData, url } from "../decrypt";
const controller = new AbortController();
const signal = controller.signal;

// use the response from getKyc to be one of the payload of quotation/save api customerInfo{}
// productInfo payload will based from the User selaction inside the app
async function saveQuotationData(quotationPayload) {
    try {
        // let payload = testData[1].payload; // static payload
        let payload = quotationPayload; // dynamic payload
        let encryptedParamsFromUrlPayload = encryptedParamsDynamic(payload);
        let csrfTokenFromUrlPayload = buildCsrfToken(encryptedParamsFromUrlPayload);

        const fetchDataPromise = fetch(`${url}/${testData[1].api}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                csrfToken: csrfTokenFromUrlPayload,
                rid: 1,
            },
            // body: JSON.stringify({ params: encryptedParams }), // static encryption
            body: JSON.stringify({
                params: encryptedParamsFromUrlPayload,
            }), // dynamic encryption
            signal,
        });

        const timeoutId = setTimeout(() => {
            controller.abort();
        }, 20000);

        const fetchData = await fetchDataPromise;

        let response = null;
        if (fetchData.ok) {
            clearTimeout(timeoutId);
            response = await fetchData.json();
        } else {
            response = [{ status: "FAILED" }];
        }
        return response;
    } catch (error) {
        if (error.name === "AbortError") {
            console.log("aborted", error.name);
            return [{ status: "TIMEOUT REACHED" }];
        } else {
            console.log("error", error);
        }
    }
}

async function decryptQuotationSaveResponse(quotationPayload) {
    const toDecrypt = await saveQuotationData(quotationPayload[0]);
    let response = null;
    if (toDecrypt["status"] === "SUCCESS") {
        const successresponse = [{ status: toDecrypt["status"] }, JSON.parse(decrypt(toDecrypt["response"]))];
        // response = JSON.parse(decrypt(toDecrypt["response"]));
        response = successresponse;
    } else {
        response = toDecrypt;
    }
    return response;
}

export { decryptQuotationSaveResponse };
