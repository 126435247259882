import { createContext, useContext, useState } from "react";

const NotifContext = createContext();

export function useNotifContext() {
    return useContext(NotifContext);
}

export function NotifProvider({ children }) {
    const [errorData, setErrorData] = useState({});
    const [vanErrorData, setVanErrorData] = useState({});

    const updateErrorData = (newData) => {
        setErrorData(newData);
        // console.log(errorData);
    };

    const updateVanErrorData = (newData) => {
        setVanErrorData(newData);
        // console.log(vanErrorData);
    };

    return (
        <NotifContext.Provider
            value={{
                errorData,
                vanErrorData,
                updateErrorData,
                updateVanErrorData,
            }}
        >
            {children}
        </NotifContext.Provider>
    );
}
