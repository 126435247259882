import helplogo from "assets/images/need-help-logo.png";

const NeedHelp = () => {
    return (
        <div>
            <div className="mt-5 mb-8">
                <div className="px-5">
                    <div className="px-3 py-5">
                        <div className="text-left rounded">
                            <div className="flex justify-center gap-8">
                                <div className="place-self-center">
                                    <img src={helplogo} alt="need help logo" width="250px" />
                                </div>
                                <div className="leading-normal">
                                    <p className="font-bold mb-2">Need Help?</p>
                                    <span className="text-gray-500">Should you need any assistance or have any inquiries, please contact</span>
                                    <br />
                                    {/* <span className="text-blue-600 font-bold"> </span> */}
                                    <a href="mailto: insure@bpims.com" className="hover:underline cursor-pointer">
                                        <span className="text-defaultblue">insure@bpims.com</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NeedHelp;
