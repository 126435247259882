// import likelogo from "assets/images/like-logo.png";
import { useEffect } from "react";
import { useNavigate, useRouteError, useSearchParams } from "react-router-dom";
import { HiXCircle } from "react-icons/hi";

const ErrorPage = () => {
    let error = useRouteError();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const nextProcess = () => {
        const customerIdParams = searchParams.get("customerId") !== null ? searchParams.get("customerId") : localStorage.getItem("customerId");
        navigate("/intro/?customerId=" + customerIdParams);
    };

    useEffect(() => {
        // console.log(searchParams.get("customerId"));
        // console.log("caught error:", error?.message);
    }, []);

    return (
        <div>
            <div className="flex flex-col h-screen bg-slate-50">
                <div className="mb-auto">
                    <div className="flex justify-center mt-16 mb-12">
                        <div className="bg-blue-50 rounded-full p-3">
                            {/* <img src={likelogo} alt="Like logo" /> */}
                            <HiXCircle className="text-defaultblue w-48 h-48" />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex mt-12 px-10 text-3xl font-bold text-defaultblue text-center">Snap! Error occured.</div>
                    </div>
                    <div className="px-12 mt-5 mb-6 text-center text-lg text-gray-500 leading-relaxed">
                        <p>Something went wrong.</p>
                    </div>
                </div>

                <div className="p-6">
                    <button className="bg-defaultblue border-defaultblue border-4 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => nextProcess()}>
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
