let reactSlickVar = {
    dots: true,
    infinite: false,
    speed: 100,
    swipeToSlide: true,
    slidesToShow: 1,
    touchThreshold: 10,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    appendDots: (dots) => (
        <div>
            <ul>{dots}</ul>
        </div>
    ),
    customPaging: (i) => (
        <div
            style={{
                height: "13px",
                borderRadius: "9999px",
            }}
        ></div>
    ),
};

export { reactSlickVar };
