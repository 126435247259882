import Navigator from "components/Navigator";
import { Select } from "flowbite-react";
import { Link } from "react-router-dom";

const CancelPolicy = () => {
    return (
        <div className="bg-slate-100">
            <div className="flex flex-col h-screen">
                <Navigator title={"Cancel Policy"} useRouterHistory={true} />
                <div className="text-blue-950 mb-auto">
                    <div className="px-5 py-5">
                        <div className="font-bold text-lg mb-5">We’re sad to see you go.</div>
                        <div className="text-md opacity-90 mb-8 leading-relaxed">
                            Before you go, we’d love to hear from you on how we can improve. Can you tell us your reason for cancelling?
                        </div>
                        <div className="mb-5">
                            <div className="text-md font-bold mb-3">Why are you cancelling?</div>
                            <div className="max-w-md" id="select">
                                <Select id="countries" sizing="lg" required>
                                    <option value={""}>Please Select</option>
                                    <option>Lorem Ipsum 1</option>
                                    <option>Lorem Ipsum 2</option>
                                    <option>Lorem Ipsum 3</option>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-6">
                    <Link to="/management/cancel_success">
                        <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold">Submit</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CancelPolicy;
