import { useNavigate } from "react-router-dom";
import { HiExclamationCircle } from "react-icons/hi";

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="flex flex-col h-screen bg-slate-50">
                <div className="mb-auto">
                    <div className="flex justify-center mt-16 mb-12">
                        <div className="bg-blue-50 rounded-full p-3">
                            <HiExclamationCircle className="text-blue-500 w-48 h-48" />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className="flex mt-12 px-10 text-3xl font-bold text-blue-500">404 Not Found.</div>
                    </div>
                    <div className="px-12 mt-5 mb-6 text-center text-lg text-gray-500 leading-relaxed">
                        <p>The page your trying to visit doesn't exist.</p>
                    </div>
                </div>

                <div className="p-6">
                    <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => navigate(-1)}>
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
