const logger = {
    hostname: window.location.hostname,
    log: (source, message, data) => {
        try {
            if (logger.hostname !== "uat.bpims.celeztia.com" && logger.hostname !== 'gcyber.bpimsapps.com') {
                console.log(`source: ${source} | message: ${message} | data: `, data);
            }
        } catch (err) {
            console.log("logger error: ", err);
        }
    },
};

export default logger;
