import Header from "components/Header";
import Navigator from "components/Navigator";
import Skeleton from "components/Skeleton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";
import validator from "validator";
import { encrypt, decrypt, restoreCryptoKey } from "services/decrypt";
import useFetchKycDropDowns from "hooks/useFetchKycDropDowns";
import Paragraph from "components/form-components/Paragraph";
import Input from "components/form-components/Input";
import Dropdown from "components/form-components/Dropdown";
import { stringutil } from "utils/stringutil";
import ConfirmationModal from "components/ConfirmationModal";
import _isEqual from "lodash/isEqual";

const Review = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { kycData, customerId, quotationData, updateKyc, updateQuotationData } = useDataContext();
    const { updateErrorData } = useNotifContext();
    const { data: kycDropDown, isPending } = useFetchKycDropDowns();
    const [kycDataLocal, setKycDataLocal] = useState(null);
    const [kycDataPending, setKycDataPending] = useState(true);

    // input models
    const [emailAddress, setEmailAddress] = useState("");
    const [gender, setGender] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [telNo, setTelNo] = useState("");
    const [occupationClass, setOccupationClass] = useState("");
    const [occupation, setOccupation] = useState("");
    const [occupationDesc, setOccupationDesc] = useState("");
    const [tin, setTin] = useState("");
    const [idType, setIdType] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [province, setProvince] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    // data bindings
    const [genderOptions, setGenderOptions] = useState([]);
    const [maritalOptions, setMaritalOptions] = useState([]);
    const [idTypeOptions, setIdTypeOptions] = useState([]);
    const [provinceOptions, setProvinceOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [postalOptions, setPostalOptions] = useState([]);
    const [occupationClassOptions, setOccupationClassOptions] = useState([]);
    const [occupationOptions, setOccupationOptions] = useState([]);

    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [optionsBound, setOptionsBound] = useState(false);

    const updateEmailAddress = (e) => {
        setEmailAddress(e.target.value);
    };

    const updateGender = (e) => {
        setGender(e);
    };

    const updateMaritalStatus = (e) => {
        setMaritalStatus(e);
    };

    const updateTelNo = (e) => {
        // const newValue = e.target.value.replace(/[^0-9]/g, "");
        // setTelNo(newValue);
        setTelNo(kycDataLocal?.mobileNumber);
    };

    const updateOccupation = (e) => {
        setOccupation(e);
        setOccupationDesc(e.label);
    };

    const updateOccupationClass = (e) => {
        setOccupationClass(e);
        selectOccupation(e.value);
    };

    const updateTin = (e) => {
        const newValue = e.target.value.replace(/[^0-9]/g, "");
        setTin(newValue);
    };

    const updateIdType = (e) => {
        setIdType(e);
    };

    const updateIdNumber = (e) => {
        const newValue = e.target.value.replace(/[^0-9A-Za-z]/g, "").toUpperCase();
        setIdNumber(newValue);
    };

    const updateProvince = (e) => {
        setProvince(e);
        selectCity(e.value);
        setPostalCode("")
    };

    const updateCity = (e) => {
        setCity(e);
        selectPostalCode(province.value, e.value);
    };

    const updatePostalCode = (e) => {
        setPostalCode(e);
    };

    async function selectCity(provinceCd) {
        setCity("");
        return new Promise((resolve, reject) => {
            const cityOpt = kycDropDown.BMM092.map((obj) => {
                return { value: obj.cityCd, label: obj.cityName, provinceCd: obj.provinceCd };
            });
            const matchedCity = cityOpt.filter((obj) => obj.provinceCd == provinceCd);
            setCityOptions([...matchedCity]);
            resolve(matchedCity);
        });
    }

    async function selectPostalCode(provinceCd, cityCd) {
        setPostalCode("");
        return new Promise((resolve, reject) => {
            const postalOptions = kycDropDown.BMM019.map((obj) => {
                return { value: obj.postalCd, label: `${obj?.bmCode} - ${obj?.postalDesc}`, provinceCd: obj.provinceCd, cityCd: obj.cityCd };
            });
            const matchedPostal = postalOptions.filter((obj) => obj.provinceCd == provinceCd && obj.cityCd == cityCd);
            setPostalOptions([...matchedPostal]);
            resolve(matchedPostal);
        });
    }

    async function selectOccupation(occupationClassCd) {
        setOccupation("");
        return new Promise((resolve, reject) => {
            const occupationOptions = kycDropDown.BMM049.map((obj) => {
                return { value: obj.occupationCd, label: `${stringutil.titleCase(obj.occupationDesc)}`, occupationClassCd: obj.occupationClassCd };
            });
            const matchedOccupation = occupationOptions.filter((obj) => obj.occupationClassCd == occupationClassCd);
            matchedOccupation.sort((a, b) => a.label.localeCompare(b.label));
            if(matchedOccupation.length){
                setOccupationOptions([...matchedOccupation]);
            }else{
                setOccupationOptions([]);
                setOccupation(null);
                setOccupationDesc(null);
            }
            resolve(matchedOccupation);
        });
    }

    const checkKycData = async (data) => {
        await restoreCryptoKey();
        // If kycData in the Context is missing. Check if theres available in localStorage
        const kycDataLocal = kycData || JSON.parse(decrypt(localStorage.getItem("kycData")));

        // If the kycData is missing also in the localStorage spit the Error
        if (!kycDataLocal) {
            updateErrorData({
                showModal: true,
                modalType: "error",
                modalMessage: "Something went wrong.",
                redirectUrl: "/intro/?customerId=" + localStorage.getItem("customerId"),
            });
        } else {
            setKycDataLocal(kycDataLocal);
            updateKyc(kycDataLocal);
            logger.log(location.pathname, "kycData: checkKycData", kycDataLocal);
            // note: if kyc data -> kyc input value are present bind it to the kyc inputs.
            if (kycDataLocal?.kycInputs) {
                restoreKycInputs(kycDataLocal, data);
            }
        }
    };

    const restoreKycInputs = async (kycDataLocal, data) => {
        // data structure 0 => gender, 1 => marital, 2 => id type, 3 => province, 4 => occupation class
        if(kycDataLocal?.kycInputs?.emailAddress === undefined){
            setEmailAddress(kycDataLocal?.emailAddress);
        }else{
            setEmailAddress(kycDataLocal?.kycInputs?.emailAddress || "");
        }

        if (kycDataLocal?.kycInputs !== undefined && Object.keys(kycDataLocal?.kycInputs).length && data.length) {
            
            setGender(data[0].find((item) => item.value == kycDataLocal?.kycInputs?.genderCd));
            setMaritalStatus(data[1].find((item) => item.value == kycDataLocal?.kycInputs?.maritalStatCd));
            setTelNo(kycDataLocal?.kycInputs?.telNo || "");

            // restore occupation class & occupation
            const occupationClass = data[4].find((item) => item.value == kycDataLocal?.kycInputs?.occupationClass);
            if(occupationClass !== undefined){
                setOccupationClass(occupationClass);            
                const selectocccupation = await selectOccupation(occupationClass.value);
                if(kycDataLocal?.kycInputs?.occupationCd !== null){
                    setOccupation(selectocccupation.find((item) => item.value == kycDataLocal?.kycInputs?.occupationCd));
                    setOccupationDesc(kycDataLocal?.kycInputs?.occupationDesc);
                }else{
                    setOccupation(kycDataLocal?.kycInputs?.occupationCd);
                    setOccupationDesc(kycDataLocal?.kycInputs?.occupationDesc);
                }
            }

            setTin(kycDataLocal?.kycInputs?.tin || "");
            setIdType(kycDataLocal?.kycInputs?.idTypeCd || "");
            setIdType(data[2].find((item) => item.value == kycDataLocal?.kycInputs?.idTypeCd));
            setIdNumber(kycDataLocal?.kycInputs?.idNumber || "");
            setProvince(data[3].find((item) => item.value == kycDataLocal?.kycInputs?.provinceCd));

            // assign city to the city dropdown on load if theres existing city selection.
            if (kycDataLocal?.kycInputs) {
                // re-assign city according to the selected province
                const selectcity = await selectCity(kycDataLocal?.kycInputs?.provinceCd);
                setCity(selectcity.find((item) => item.value == kycDataLocal?.kycInputs?.cityCd));

                // re-assign postal code according to the selected city
                const selectpostal = await selectPostalCode(kycDataLocal?.kycInputs?.provinceCd, kycDataLocal?.kycInputs?.cityCd);
                setPostalCode(selectpostal.find((item) => item.value == kycDataLocal?.kycInputs?.postalCd));
            }
            setFormSubmitted(true);
            setKycDataPending(false);
        } else {
            setKycDataPending(false);
        }
    };

    async function bindOptionsAsync() {
        try {
            const bindOptionsPromise = new Promise((resolve, reject) => {
                try {
                    const promises = [];
                    setKycDataPending(true);

                    const genderPromise = new Promise((genderResolve, genderReject) => {
                        const genderOpt = kycDropDown.BMM016.map((obj) => ({
                            value: obj.genderCd,
                            label: obj.genderDesc,
                        }));
                        setGenderOptions([...genderOpt]);
                        genderResolve(genderOpt);
                    });
                    promises.push(genderPromise);

                    const maritalPromise = new Promise((maritalResolve, maritalReject) => {
                        const maritalOpt = kycDropDown.BMM018.map((obj) => ({
                            value: obj.maritalStatCd,
                            label: obj.maritalDesc,
                        }));
                        setMaritalOptions([...maritalOpt]);
                        maritalResolve(maritalOpt);
                    });
                    promises.push(maritalPromise);

                    const idTypePromise = new Promise((idTypeResolve, idTypeReject) => {
                        const idTypeOpt = kycDropDown.BMM050.map((obj) => ({
                            value: obj.idTypeCd,
                            label: obj.idTypeDesc,
                        }));
                        setIdTypeOptions([...idTypeOpt]);
                        idTypeResolve(idTypeOpt);
                    });
                    promises.push(idTypePromise);

                    const provincePromise = new Promise((provinceResolve, provinceReject) => {
                        const provinceOpt = kycDropDown.BMM047.map((obj) => ({
                            value: obj.provinceCd,
                            label: obj.provinceName,
                        }));
                        setProvinceOptions([...provinceOpt]);
                        provinceResolve(provinceOpt);
                    });
                    promises.push(provincePromise);

                    const occupationClassPromise = new Promise((occupationClassResolve, occupationClassReject) => {
                        const occupationClassOpt = kycDropDown.BMM068.map((obj) => ({
                            value: obj.occupationClassCd,
                            label: `${obj.occupationClassDesc}`,
                        }));
                        setOccupationClassOptions([...occupationClassOpt]);
                        occupationClassResolve(occupationClassOpt);
                    });
                    promises.push(occupationClassPromise);

                    Promise.all(promises)
                        .then((result) => {
                            setTimeout(() => {
                                resolve({
                                    status: "ok",
                                    message: "Bindings Done",
                                    data: result,
                                });
                            }, 2000);
                        })
                        .catch((error) => {
                            reject({
                                status: "failed",
                                message: error,
                            });
                        });
                } catch (error) {
                    reject({
                        status: "failed",
                        message: error,
                    });
                }
            });

            const result = await bindOptionsPromise;
            if (result) {
                if (result.status === "ok") {
                    setOptionsBound(true);
                    checkKycData(result.data);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const returnUnFilled = (params) => {
        let errorMessages = [];
        
        const falseValues = Object.entries(params)
        .filter(([key, value]) => value === false)
        .map(([key, value]) => key);

        falseValues.forEach(key => {
            switch(key) {
              case "emailAddressValid":
                errorMessages.push("Email Address");
                break;
              case "genderValid":
                errorMessages.push("Gender");
                break;
              case "maritalStatusValid":
                errorMessages.push("Marital Status");
                break;
              case "telNoValid":
                errorMessages.push("Telephone Number");
                break;
              case "occupationValid":
                errorMessages.push("Occupation");
                break;
              case "tinValid":
                errorMessages.push("TIN");
                break;
              case "idTypeValid":
                errorMessages.push("ID Type");
                break;
              case "idNumberValid":
                errorMessages.push("ID Number");
                break;
              case "provinceValid":
                errorMessages.push("Province");
                break;
              case "cityValid":
                errorMessages.push("City");
                break;
              case "postalCodeValid":
                errorMessages.push("Postal Code");
                break;
              default:
                logger.log(location.pathname, 'unfilled inputs', 'all is good.');
            }
          });

        return errorMessages;
    }

    const nextStep = (linkTo) => {
        const emailAddressValid = validator.isEmail(emailAddress);
        const genderValid = gender !== "";
        const maritalStatusValid = maritalStatus !== "";
        // const telNoValid = telNo.length === 10 || telNo.length === 11;
        const telNoValid = true;
        let occupationValid = false;
        if(occupationOptions.length){
            occupationValid = occupation !== "";
        }else{
            occupationValid = true;
        }
        const tinValid = tin.length === 12;
        const idTypeValid = idType !== "";
        const idNumberValid = idNumber.length >= 5;
        const provinceValid = province !== "";
        const cityValid = city !== "";
        const postalCodeValid = postalCode !== "";

        const formValid =
            emailAddressValid && genderValid && maritalStatusValid && telNoValid && occupationValid && tinValid && idTypeValid && idNumberValid && provinceValid && cityValid && postalCodeValid;
        setFormSubmitted(true);

        let unfilledFields = returnUnFilled({
            emailAddressValid, genderValid, maritalStatusValid, telNoValid, occupationValid, tinValid, idTypeValid, idNumberValid, provinceValid, cityValid, postalCodeValid
        });

        // console.log(
        //     emailAddressValid, genderValid, maritalStatusValid, telNoValid, occupationValid, tinValid, idTypeValid, idNumberValid, provinceValid, cityValid, postalCodeValid
        // );

        if (formValid) {
            const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");

            // note: add kyc inputs into the quotatioData array -> customerInfo object
            updateQuotationData([
                // if customerId is undefined in the quotationData due to page refresh. Re-fill it. The refilling automatically happens here.
                { customerId: customerIdParams },
                {
                    customerInfo: {
                        emailAddress: emailAddress,
                        genderCd: gender,
                        maritalStatCd: maritalStatus,
                        telNo: kycData?.mobileNumber,
                        occupationClass: occupationClass,
                        occupationCd: occupation,
                        occupationDesc: occupationDesc,
                        tin: tin,
                        idTypeCd: idType,
                        idNumber: idNumber,
                        provinceCd: province,
                        cityCd: city,
                        postalCd: postalCode,
                    },
                },
            ]);

            // note: append kyc Input values to the kycData to restore the kyc inputted by the User when page reload happens.
            kycData.kycInputs = {
                emailAddress: emailAddress,
                genderCd: parseInt(gender?.value),
                maritalStatCd: parseInt(maritalStatus?.value),
                telNo: kycData?.mobileNumber,
                occupationClass: parseInt(occupationClass?.value),
                occupationCd: occupationOptions.length ? parseInt(occupation?.value) : occupation,
                occupationDesc: occupationDesc,
                tin: tin,
                idTypeCd: idType?.value,
                idNumber: idNumber,
                provinceCd: parseInt(province?.value),
                cityCd: parseInt(city?.value),
                postalCd: parseInt(postalCode?.value),
            };

            // note: after appending save it to the kycData context. then save the kycData to the localStorage to prevent data loss on refresh.
            updateKyc(kycData);
            localStorage.setItem("kycData", encrypt(kycData));

            logger.log(location.pathname, "kycData: nextStep", kycData);
            // logger.log(location.pathname, "quotationData: nextStep", quotationData);

            // check if the form is edited.
            const kycDataIntact = localStorage.getItem("kycDataIntact");
            if (kycDataIntact !== null) {
                const decryptedKycDataIntact = decrypt(kycDataIntact);
                logger.log(location.pathname, "kycDataIntact", JSON.parse(decryptedKycDataIntact));
                logger.log(location.pathname, "kycInputs", kycData?.kycInputs);
                const isKycInputsEqual = _isEqual(JSON.parse(decryptedKycDataIntact), kycData?.kycInputs);

                if (!isKycInputsEqual) {
                    // if edited show a confirmation modal
                    setConfirmationMessage("Do you want to Save Changes?");
                    setModalOpen(true);
                } else {
                    // if not just continue to the next page
                    navigate(`${linkTo}/?customerId=${customerIdParams}`);
                }
            }
        } else {
            logger.log(location.pathname, "unfilled inputs", unfilledFields);

            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: `Please complete the form to proceed.\nFill-out the following field${unfilledFields.length > 1 ? 's':''}:\n${unfilledFields.join(", ")}.`,
                redirectUrl: null,
                withFormatting: true,
            });
        }
    };

    const handleConfirm = (linkTo) => {
        setModalOpen(false);
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    const handleCancel = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        if (!isPending) {
            bindOptionsAsync();
        }
    }, [isPending]);

    return (
        <div>
            {!kycDataPending && !isPending && (
                <div className="flex flex-col h-screen">
                    <Navigator title={"Review"} useRouterHistory={true} />
                    <Header currentStep="1" />

                    <div className="h-auto mb-auto">
                        <div className="mt-6"></div>
                        <Paragraph title="Full Name" content={kycDataLocal?.displayName} />

                        <Paragraph title="Mobile Number" content={kycDataLocal?.mobileNumber} />

                        <Input
                            label="Email Address"
                            value={emailAddress}
                            onChange={updateEmailAddress}
                            isValid={validator.isEmail(emailAddress)}
                            validationMessage={"Please enter a valid email address."}
                            maxlength={50}
                        />

                        <Dropdown
                            label="Gender"
                            placeholder={"Select Gender..."}
                            options={genderOptions}
                            value={gender}
                            onChange={updateGender}
                            isValid={gender === "" && formSubmitted ? false : true}
                            validationMessage={"Please select a Gender."}
                        />

                        <Dropdown
                            label="Marital Status"
                            placeholder={"Select Marital Status..."}
                            options={maritalOptions}
                            value={maritalStatus}
                            onChange={updateMaritalStatus}
                            isValid={maritalStatus === "" && formSubmitted ? false : true}
                            validationMessage={"Please select a Marital Status."}
                        />

                        <Input
                            label="Telephone Number"
                            placeholder={"00-1234-5678"}
                            value={kycDataLocal?.mobileNumber}
                            onChange={updateTelNo}
                            // isValid={telNo.length !== 10 && telNo.length !== 11 && telNo.length > 0 ? false : true}
                            isValid={true}
                            validationMessage={"Please enter a valid Telephone Number."}
                            maxlength={11}
                            hidden
                        />

                        {occupationClassOptions.length > 0 && (
                            <Dropdown
                                label="Select Occupation Class"
                                placeholder={"Occupation Class..."}
                                options={occupationClassOptions}
                                value={occupationClass}
                                onChange={updateOccupationClass}
                                isValid={occupationClass === "" && formSubmitted ? false : true}
                                validationMessage={"Please select a Occupation Class."}
                            />
                        )}

                        {occupationOptions.length > 0 && (
                            <Dropdown
                                label="Occupation"
                                placeholder={"Select Occupation..."}
                                options={occupationOptions}
                                value={occupation}
                                onChange={updateOccupation}
                                isValid={occupation === "" && formSubmitted ? false : true}
                                validationMessage={"Please select a Occupation."}
                            />
                        )}

                        <Input
                            label="Tin"
                            placeholder={"123-456-789-000"}
                            value={tin}
                            onChange={updateTin}
                            isValid={tin.length !== 12 && tin.length >=  0 ? false : true}
                            validationMessage={"Please enter a valid TIN."}
                            maxlength={12}
                        />

                        <Dropdown
                            label="ID Type"
                            placeholder={"Select ID Type..."}
                            options={idTypeOptions}
                            value={idType}
                            onChange={updateIdType}
                            isValid={idType === "" && formSubmitted ? false : true}
                            validationMessage={"Please select a ID Type."}
                        />

                        {idType && (
                            <Input
                                label="ID Number"
                                value={idNumber}
                                onChange={updateIdNumber}
                                isValid={idNumber.length < 5 && idNumber.length >= 0 ? false : true}
                                validationMessage={"Please enter a valid ID Number."}
                                maxlength={20}
                            />
                        )}

                        <Dropdown
                            label="Province"
                            placeholder={"Select Province..."}
                            options={provinceOptions}
                            value={province}
                            onChange={updateProvince}
                            isValid={province === "" && formSubmitted ? false : true}
                            validationMessage={"Please select a Province."}
                        />

                        {cityOptions.length > 0 && (
                            <Dropdown
                                label="City"
                                placeholder={"Select City..."}
                                options={cityOptions}
                                value={city}
                                onChange={updateCity}
                                isValid={city === "" && formSubmitted ? false : true}
                                validationMessage={"Please select a City."}
                            />
                        )}

                        {postalOptions.length > 0 && (
                            <Dropdown
                                label="Postal Code - Barangay"
                                placeholder={"Select Postal Code - Barangay..."}
                                options={postalOptions}
                                value={postalCode}
                                onChange={updatePostalCode}
                                isValid={postalCode === "" && formSubmitted ? false : true}
                                validationMessage={"Please select a Postal Code - Barangay."}
                            />
                        )}

                        <div className="mb-6"></div>
                    </div>

                    <div className="bg-slate-100 p-6 border-t">
                        <div className="mb-8 px-2">
                            <p className="text-sm text-center opacity-90 leading-relaxed">Click next if your information is correct or input another GCash Virtual Account Number.</p>
                        </div>
                        <button className="bg-blue-700 rounded-full w-full mb-2 py-5 text-white font-bold" onClick={() => nextStep("/purchase/select_plan")}>
                            Next
                        </button>
                    </div>

                    <ConfirmationModal
                        showModal={modalOpen}
                        modalMessage={confirmationMessage}
                        onConfirm={() => {
                            handleConfirm("/purchase/select_plan");
                        }}
                        onCancel={handleCancel}
                    />
                </div>
            )}
            {kycDataPending && isPending && <Skeleton />}
            {kycDataPending && <Skeleton />}
        </div>
    );
};

export default Review;
