import { useEffect, useState } from "react";
import { decryptReportGetResponse } from "services/report-get";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";

const useReportGet = (quoteId) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { updateErrorData } = useNotifContext();

    async function reportGet(payload) {
        await decryptReportGetResponse(payload)
            .then((data) => {
                if (data?.length) {
                    if (data[0].status === "SUCCESS") {
                        setData(data);
                        logger.log("useReportGet", "response", data);
                        setIsPending(false);
                    } else {
                        logger.log("useReportGet", "Error", data);
                        throw new Error(data[0]?.status || "Something went wrong");
                    }
                } else {
                    setIsPending(false);
                    throw new Error("Something went wrong.");
                }
            })
            .catch((error) => {
                logger.log("useReportGet", "Catched Error", error);
                if (error.message === "TIMEOUT REACHED") {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Application Timeout.",
                        redirectUrl: "goback",
                    });
                } else {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Something went wrong.",
                        redirectUrl: null,
                    });
                }
            });
    }

    useEffect(() => {
        if (quoteId) {
            const payload = { quoteId: quoteId };
            logger.log("useReportGet", "report/get payload", payload);
            reportGet(payload);
        }
    }, [quoteId]);

    return { data, isPending };
};

export default useReportGet;
