import { Card } from "flowbite-react";

const IntroductionCard = ({ cardTitle, icon, benefits, currentindex }) => {
    function importAll(r) {
        let images = {};
        r.keys().forEach((item, index) => {
            images[item.replace("./", "")] = r(item);
        });
        return images;
    }

    const images = importAll(require.context("assets/images/icons", false, /\.(png|jpe?g|svg)$/));

    return (
        <div>
            <div className="p-5">
                <Card className="full-width text-center rounded-xl">
                    <div className="flex flex-col">
                        <div className="flex justify-center mb-3">{<img src={images[icon]} alt="logo" width="100px" />}</div>
                        <div>
                            <h5 className="text-md font-bold">{cardTitle}</h5>
                        </div>
                    </div>

                    <div>
                        <div className="flex flex-col">
                            {benefits &&
                                benefits.map((item, itemindex) => {
                                    return (
                                        <div className="px-2 py-5" key={itemindex}>
                                            <div className="flex gap-4">
                                                <div className="place-self-center flex-none w-16 rounded-full bg-gray-100">
                                                    <img src={images[item.icon]} alt="logo" className="p-2 bullet-logo" />
                                                </div>
                                                <div className="leading-normal text-left flex-1 w-64">
                                                    <p className="font-bold text-md mb-2">{item.title}</p>
                                                    <span className="opacity-90 text-sm">{item.content}</span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default IntroductionCard;
