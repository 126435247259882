const Paragraph = ({ title, content }) => {
    return (
        <>
            <div className="px-5 mb-5">
                <div>
                    <p className="text-md opacity-90 mb-2">{title}</p>
                    <p className="text-md font-bold">{content}</p>
                </div>
            </div>
        </>
    );
};

export default Paragraph;
