import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "services/decrypt";
import { getReadableGcashResponse } from "services/redirect-congrats";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";

const useFetchRedirectCongrats = (urlparams) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { updateErrorData } = useNotifContext();
    const { customerId } = useDataContext();

    async function fetchGcashPayResponse(payload) {
        try {
            const gcashresponse = getReadableGcashResponse(payload);
            const quoteId = { quoteId: gcashresponse?.quoteId, ecId: gcashresponse?.ecId ?? null };
            const encryptedQuoteId = encrypt(quoteId);
            logger.log(location.pathname, "gcash response", gcashresponse);
            logger.log(location.pathname, "to be passed to URL as params to purchase/success", quoteId);
            const params = btoa(encryptedQuoteId);

            const customerIdParams = customerId !== null ? customerId : urlparams.get("customerId");
            if (gcashresponse?.errorMessage !== undefined) {
                setData(gcashresponse);
                setIsPending(false);
                updateErrorData({
                    showModal: true,
                    modalType: "error",
                    modalMessage: "Something went wrong! Please try again.",
                    modalGcashMessage:
                        "We are unable to charge your account for your GCash Card Protect premiums. Please check your wallet balance and try again so you can continue to enjoy safer card transactions.",
                    fromGcash: true,
                    redirectUrl: "/intro/?customerId=" + customerIdParams,
                });
            } else {
                setData(gcashresponse);
                setIsPending(false);
                logger.log(location.pathname, "purchase/success - params", decrypt(atob(params)));
                navigate(`/purchase/success/?params=${params}&customerId=${customerIdParams}`);
            }
        } catch (error) {
            logger.log("fetchGcashPayResponse", "error", error);
        }
    }

    useEffect(() => {
        if (urlparams) {
            setTimeout(() => {
                fetchGcashPayResponse(urlparams.get("params"));
            }, 2000);
            setIsPending(true);
        } else {
            setIsPending(false);
            localStorage.clear();
        }
    }, [urlparams]);

    return { data, isPending };
};

export default useFetchRedirectCongrats;
