import { useState } from "react";
import Select from "react-select";

const Input = ({ label, options, value, onChange, isValid, validationMessage, placeholder }) => {
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleFocus = () => {
        setIsInputFocused(true);
    };

    const handleBlur = () => {
        setIsInputFocused(false);
    };

    return (
        <>
            <div className="px-5 mb-5">
                <div>
                    <p className={`text-md opacity-90 ${isInputFocused ? "text-blue-600" : "text-blue-950"}`}>{label}</p>
                    {/* <select
                        className="select1 border-x-0 border-t-0 border-gray-200 focus:border-blue-600 text-md pl-1 py-2"
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}>
                        {options.map((option, index) => (
                            <option key={`${index}_${option.value}`} value={option.value} label={option.label} disabled={option.disabled}>
                                {option.label}
                            </option>
                        ))}
                    </select> */}
                    {/* <Select options={options} className="" /> */}
                    <Select options={options} value={value} onChange={onChange} onFocus={handleFocus} onBlur={handleBlur} placeholder={placeholder} className="mt-2"
                    styles={{
                        control: (provided) => ({
                          ...provided,
                          borderTop: 'none',
                          borderRight: 'none',
                          borderLeft: 'none',
                          borderRadius: '0',
                          boxShadow: 'none',
                          borderBottom: `${!isValid ? '1px solid red' : ''}`
                        }),
                      }}
                      style={{'borderBottom': !isValid ? '1px solid red' : ''}}
                    />
                    {!isValid && <p className="text-xs text-red-500 italic">{validationMessage}</p>}
                </div>
            </div>
        </>
    );
};

export default Input;
