import { headerContent } from "utils/variables/introductionVar";

const Header = ({ currentStep }) => {
    const openDefaultBrowser = () => {
        // window.open("https://help.gcash.com/hc/en-us/articles/4403085039513-Manage-your-GCash-Card", "_system");
        window.location.assign("https://help.gcash.com/hc/en-us/articles/4403085039513-Manage-your-GCash-Card", "_system");
    };

    return (
        <div>
            <div className="bg-slate-50 py-7 px-5 border-b-2">
                {headerContent[currentStep].step === 5 && <div className="mb-8"></div>}
                {headerContent[currentStep].step !== 5 && (
                    <div className="flex justify-between mb-4">
                        <div>
                            <span className="font-bold text-defaultblue">Step {headerContent[currentStep].step} of 4</span>
                        </div>
                        <div className="flex flex-row">
                            {headerContent.map((content, index) => {
                                return (
                                    index != 4 && (
                                        <span
                                            key={index}
                                            className={
                                                currentStep >= index
                                                    ? "bg-defaultblue h-[8px] w-[40px] rounded-full mr-3"
                                                    : "bg-gray-300 h-[8px] w-[40px] rounded-full mr-3"
                                            }
                                        ></span>
                                    )
                                );
                            })}
                        </div>
                    </div>
                )}

                <div>
                    <p className="font-bold text-xl mb-3">{headerContent[currentStep].title}</p>
                    <p className="text-sm opacity-90">
                        {headerContent[currentStep].subTitle}{" "}
                        {currentStep == 0 && (
                            <a href="#" className="hover:underline text-defaultblue" onClick={() => openDefaultBrowser()}>
                                here.
                            </a>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Header;
