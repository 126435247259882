import arrow_right from "assets/images/arrow-right.png";
import autorenewal from "assets/images/auto-renewal.png";
// import cancelpolicy from "assets/images/cancel-policy.png";
import claim from "assets/images/edit-small.png";
// import insuranceactivation from "assets/images/insurance-activation.png";
import transaction_history from "assets/images/transaction-history.png";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "store/DataContext";

const Services = ({ setShowModalState, selectTab, myIndex, fromLsq }) => {
    const { customerId } = useDataContext();
    const navigate = useNavigate();

    const nextStep = (linkTo) => {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        navigate(`${linkTo}/?customerId=${customerIdParams}`);
    };

    return (
        <div className="">
            <div className="mt-5 px-5 mb-5" style={{'minHeight': 'calc(100vh - 49vh)', 'maxHeight': 'calc(100vh - 49vh)'}}>
                <div className="bg-white rounded-sm">
                    {/* <Link className="flex justify-start border-b-2 p-5 hover:cursor-pointer" to="">
                        <div className="mx-2">
                            <img src={insuranceactivation} alt="logo" width={"30px"} />
                        </div>
                        <div className="font-bold text-md flex-auto place-self-center text-blue-950 opacity-90">Insurance Activation</div>
                        <div>
                            <img src={arrow_right} alt="logo" width={"30px"} />
                        </div>
                    </Link> */}
                    {!fromLsq && (
                        <div className="flex justify-start border-b-2 p-5 hover:cursor-pointer" onClick={() => nextStep("/management/auto_renewal")}>
                            <div className="place-self-center mx-2">
                                <img src={autorenewal} alt="logo" width={"30px"} />
                            </div>
                            <div className="font-bold text-md flex-auto place-self-center text-blue-950  opacity-90">Auto Renewal</div>
                            <div>
                                <img src={arrow_right} alt="logo" width={"30px"} />
                            </div>
                        </div>
                    )}

                    <div className="flex justify-start border-b-2 p-5 hover:cursor-pointer" onClick={() => nextStep("/management/make_claim")}>
                        <div className="place-self-center mx-2">
                            <img src={claim} alt="logo" width={"30px"} />
                        </div>
                        <div className="font-bold text-md flex-auto place-self-center text-blue-950 opacity-90">Make a Claim</div>
                        <div>
                            <img src={arrow_right} alt="logo" width={"30px"} />
                        </div>
                    </div>

                    <div className="flex justify-start border-b-2 p-5 hover:cursor-pointer" onClick={() => selectTab(myIndex)}>
                        <div className="place-self-center mx-2">
                            <img src={transaction_history} alt="logo" width={"30px"} />
                        </div>
                        <div className="font-bold text-md flex-auto place-self-center text-blue-950 opacity-90">Transaction History</div>
                        <div>
                            <img src={arrow_right} alt="logo" width={"30px"} />
                        </div>
                    </div>

                    {/* <Link className="flex justify-start p-5 hover:cursor-pointer" onClick={() => setShowModalState(true)}>
                        <div className="place-self-center mx-2">
                            <img src={cancelpolicy} alt="logo" width={"30px"} />
                        </div>
                        <div className="font-bold text-md flex-auto place-self-center text-blue-950 opacity-90">Cancel Policy</div>
                        <div>
                            <img src={arrow_right} alt="logo" width={"30px"} />
                        </div>
                    </Link> */}
                </div>
            </div>
        </div>
    );
};

export default Services;
