import Navigator from "components/Navigator";
import { useEffect, useState } from "react";
import { useDataContext } from "store/DataContext";
import { useNotifContext } from "store/NotifContext";
import ConfirmationModal from "components/ConfirmationModal";
import { dateUtil } from "utils/dateutil";
import logger from "utils/logger";
import { useLocation } from "react-router-dom";
import useSetAutoRenewal from "hooks/useSetAutoRenewal";
import Skeleton from "components/Skeleton";

const AutoRenewal = () => {
    const location = useLocation();
    const [autoRenewal, setAutoRenewal] = useState(false);
    const [currAutoRenewal, setCurrAutoRenewal] = useState(false);
    const { policyDetailsData } = useDataContext();
    const { updateErrorData } = useNotifContext();
    const [confirmationMessage, setConfirmationMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [payload, setAutoRenewSavePayload] = useState(null);
    const { data, isPending } = useSetAutoRenewal(payload);

    const autoRenewalChange = () => {
        const lastUpdate = data !== null ? data[1]?.data?.autoRenewalLastUpdate : policyDetailsData?.autoRenewUpdate;
        const status = checkAutoRenewalAvailability(new Date(lastUpdate));
        console.log(status);

        if (status.canRenew) {
            setCurrAutoRenewal(!autoRenewal);
            const toggleStatus = currAutoRenewal ? "On" : "Off";
            setConfirmationMessage("Turn " + toggleStatus + " your Policy Auto Renewal ?");
            setModalOpen(true);
        } else {
            updateErrorData({
                showModal: true,
                modalType: "warning",
                modalMessage: `Dear Customer, We have detected changes with your Auto Renewal Enrollment just an hour ago. If you wish to proceed with the update please check back in an hour and try again.`,
                redirectUrl: null,
            });
        }
    };

    const handleConfirm = () => {
        setAutoRenewal(currAutoRenewal);
        const toSession = currAutoRenewal ? 1 : 0;
        localStorage.setItem("currAutoRenewal", toSession);
        setModalOpen(false);

        // NOTE: Trigger policyFlagRenew
        const newAutoRenewalTag = localStorage.getItem("currAutoRenewal");
        setAutoRenewSavePayload({
            policyId: policyDetailsData?.policyId,
            autoRenew: parseInt(newAutoRenewalTag),
            lastAutoRenewalTrigger: policyDetailsData?.autoRenewUpdate,
            emailAddress: policyDetailsData?.emailAddress,
            displayName: policyDetailsData?.displayName ?? "N/A",
            maxCov: policyDetailsData?.maxCov,
        });
    };

    const handleCancel = () => {
        setAutoRenewal(!currAutoRenewal);
        const toSession = autoRenewal ? 1 : 0;
        localStorage.setItem("currAutoRenewal", toSession);
        setModalOpen(false);
    };

    const checkAutoRenewalAvailability = (lastUpdate) => {
        const currentTime = dateUtil.dateNow();
        const lastAutoRenewalTrigger = lastUpdate;
        lastAutoRenewalTrigger.setHours(lastAutoRenewalTrigger.getHours() - 8);
        const timeDifference = currentTime - lastAutoRenewalTrigger;

        // Convert milliseconds to seconds, minutes, hours, etc. as needed
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        logger.log(location.pathname, "Time Difference", `${days} days, ${hours % 24} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`);
        logger.log(location.pathname, "Time Difference hour only", `${hours} hours`);
        logger.log(location.pathname, "lastAutoRenewalTrigger", lastAutoRenewalTrigger);

        if (hours >= 1) {
            return { canRenew: true };
        } else {
            return { canRenew: false };
        }
    };

    useEffect(() => {
        const sessionAutoRenewal = localStorage.getItem("currAutoRenewal");
        if (sessionAutoRenewal != null) {
            // NOTE: if autoRenewalTag is present on the session dont bind just follow the stored value.
            setAutoRenewal(parseInt(sessionAutoRenewal) ? true : false);
        } else {
            // NOTE: if no autoRenewalTag in the session. bind the policy details autoRenewalTag to the state.
            setAutoRenewal(parseInt(policyDetailsData?.autoRenewTag) ? true : false);
        }
        checkAutoRenewalAvailability(new Date(policyDetailsData?.autoRenewUpdate));
    }, []);

    return (
        <div className="">
            {!isPending && (
                <div>
                    <Navigator title={"Auto Renewal"} useRouterHistory={true} />

                    <div className=" text-left rounded px-5 pt-10 mt-5">
                        <div className="flex flex-row justify-between">
                            <div className="place-self-center font-bold text-md">
                                <p className="">Auto Renewal</p>
                            </div>
                            <div className="place-self-center">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={autoRenewal}
                                        onChange={(e) => {
                                            autoRenewalChange();
                                        }}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="p-5">
                        <div className="mb-8">
                            {/* <p className="text-sm text-center text-gray-500">
                        Your premium will be auto-deducted from your GCash wallet every 5th of the month. Your plan will auto-renew by{" "}
                        {policyDetailsData && dateUtil.formatDateToReadable(policyDetailsData?.expiryDate)}. You are currently enrolled in GCash Card
                    </p> */}
                            {autoRenewal && (
                                <p className="text-sm text-center text-gray-500">
                                    Protect’s Auto-Renew program. Your wallet will be charged automatically on {localStorage.getItem("ndd")} for the premiums of your selected period of coverage.
                                    <br />
                                    <br />
                                    As long as premiums are successfully charged, your GCash Card Policy will remain active and in force.
                                </p>
                            )}
                            {!autoRenewal && (
                                <p className="text-sm text-center text-gray-500">
                                    You are NOT currently enrolled in GCash Card Protect’s Auto-Renew program. Your coverage will terminate at expiry.
                                    <br />
                                    <br />
                                    Turn on Auto-Renewal button to ensure that your GCash Card Protect policy remains active and in force. When your current policy expires, your wallet will be charged
                                    automatically for the premiums of your selected period of coverage.
                                </p>
                            )}
                        </div>
                    </div>

                    <ConfirmationModal showModal={modalOpen} modalMessage={confirmationMessage} onConfirm={handleConfirm} onCancel={handleCancel} />
                </div>
            )}
            {isPending && <Skeleton />}
        </div>
    );
};

export default AutoRenewal;
