import NeedHelp from "components/NeedHelp";
import { dateUtil } from "utils/dateutil";
import { numberUtil } from "utils/numberutil";

const Activity = ({ policyDetailsData }) => {
    const screenHeight = window.innerHeight;
   
    let margin;
    if (screenHeight >= 1020) {
        margin = "55%";
    } else if (screenHeight >= 600) {
        margin = "20%";
    } else if (screenHeight >= 400) {
        margin = "15%";
    } else {
        margin = "10%";
    }

    return (
        <div>
            <div className="" style={{ height: "calc(100vh - 46%)", minHeight: "calc(100vh - 46%)", maxHeight: "calc(100vh - 46%)" }}>
                <div className="">
                    <div className="bg-white text-blue-950 ">
                        <div className="px-5 py-5 border-y border-gray-300">
                            <div className="flex justify-between">
                                <div>
                                    <p className="text-md font-bold tracking-tighter leading-relaxed opacity-90">
                                        Here is your activity history. Some transactions may take up to 24 hours before posting.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {policyDetailsData &&
                            policyDetailsData.transactions.map((data, index) => {
                                return (
                                    <div className="px-5 py-5 border-b hover:bg-slate-100 active:bg-slate-100" key={`${data?.tranNo}_${index}`}>
                                        <div className="flex justify-start gap-3">
                                            <div className="font-bold flex-auto place-self-center text-blue-950">
                                                <div className="text-md mb-1">Paid Card Protect</div>
                                                <div className="text-sm text-gray-500">{dateUtil.formatDateToReadableWithTime(data?.paytDate)}</div>
                                            </div>
                                            <div className="text-md font-bold text-blue-950 place-self-center">PHP {numberUtil.formatNumber(data?.amtDue)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="" style={{ marginTop: margin }}>
                    <NeedHelp />
                </div>
            </div>
        </div>
    );
};

export default Activity;
