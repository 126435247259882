import ModalPrompt from "components/ModalPrompt";
import VanModalPrompt from "components/VanModalPrompt";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Layout = () => {
    useEffect(() => {
        // NOTE: Disable Pinch Zoom when in Mobile
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };
        document.addEventListener("touchmove", disablePinchZoom, { passive: false });
        return () => {
            document.removeEventListener("touchmove", disablePinchZoom);
        };
    }, []);

    return (
        <div>
            <Outlet />
            <div>
                <ModalPrompt />
                <VanModalPrompt />
            </div>
        </div>
    );
};

export default Layout;
