import { useEffect, useState } from "react";
import { HiInformationCircle, HiOutlineCheckCircle, HiOutlineExclamationCircle, HiX, HiXCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useNotifContext } from "store/NotifContext";
const VanModalPrompt = () => {
    const { vanErrorData, updateVanErrorData } = useNotifContext();

    const navigate = useNavigate();
    const [showModalState, setShowModalState] = useState(false);
    const [modalTypeState, setModalTypeState] = useState(false);
    const [modalMessageState, setModalMessage] = useState(false);
    const props = {
        showModalState,
        setShowModalState,
        modalTypeState,
        setModalTypeState,
        modalMessageState,
        setModalMessage,
    };

    const onCloseAction = () => {
        props.setShowModalState(false);
        navigate(-1);
        // if (vanErrorData.redirectUrl != null) {
            // if (vanErrorData.redirectUrl) {
            //     navigate(-1);
            //     alert("test")
            // } else {
            //     navigate(vanErrorData.redirectUrl);
            //     alert("hello")
            // }
        // }
        updateVanErrorData({});
    };
    
    const redirectUrl = () => {
        props.setShowModalState(false);
        // window.location.replace("gcash://com.mynt.gcash/app/006300000200?url=https%3A%2F%2Fm.gcash.com%2Fgcash-cardlinking-web%2Findex.html%23%2Flinked-cards");
        window.location.replace("gcash://com.mynt.gcash/app/006300050500?entryPoint=crm");
    }


    useEffect(() => {
        setShowModalState(vanErrorData?.showModal);
        setModalTypeState(vanErrorData?.modalType);
        setModalMessage(vanErrorData?.modalMessage);
    }, [vanErrorData]);

    return (
        <div className="">
            {showModalState && (
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="bg-black w-full h-full absolute opacity-40"></div>
                    <div className="relative w-auto my-6 max-w-3xl mx-5">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-5 rounded-t ">
                                <h3 className="text-xl font=semibold"></h3>
                                <button className="bg-transparent border-0 text-black float-right place-self-center" onClick={() => onCloseAction()}>
                                    <HiX className="h-6 w-6 " />
                                </button>
                            </div>
                            <div className="relative p-6 flex-auto">
                                <div className="px-6 py-12">
                                    {/* {modalTypeState === "error" && <HiXCircle className="mx-auto mb-4 h-20 w-20 text-red-500" />}
                                    {modalTypeState === "success" && <HiOutlineCheckCircle className="mx-auto mb-4 h-20 w-20 text-green-500" />}
                                    {modalTypeState === "warning" && (
                                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-20 w-20 text-orange-500" />
                                    )}
                                    {modalTypeState === "info" && <HiInformationCircle className="mx-auto mb-4 h-20 w-20 text-blue-500" />} */}

                                    <p className="text-center text-lg leading-relaxed text-blue-600 font-extrabold mb-5">
                                        Thank you for your interest in GCash Card Protect.
                                        <br />
                                        <span className="text-md">
                                            {/* We're still preparing the system to ensure a delightful journey for you. Please check back again soon. */}
                                            {/* To proceed, you must have an activated GCash VISA Card. <br /> If you have activated your card, please allow us up to 5 business days to process your policy and visit again soon. */}
                                        </span>
                                        {/* Order your GCash VISA Card and activate it to enjoy GCash Card Protect. */}
                                        {/* Oops! this doesnt seem to be a valid <br /> Virtual Account Number */}
                                    </p>
                                    {/* <p className="text-center text-md leading-relaxed opacity-90">
                                        Order your GCash VISA Card and activate it to enjoy GCash Card Protect.
                                    </p> */}
                                    <p className="text-center text-lg text-black-400">
                                    To proceed, you must have an activated GCash VISA Card. <br /> If you have activated your card, please allow us up to 5 business days to process your policy and visit again soon.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    type="button"
                                    className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={() => onCloseAction()}
                                >
                                    Buy Now / Activate
                                </button>np
                            </div> */}
                            <div className="px-5">
                        <div className="pb-10">
                            <button
                                className="bg-defaultblue border-defaultblue hover:border-blue-800 border-4 rounded-full w-full p-5 text-white font-bold tracking-widest hover:bg-blue-800"
                                onClick={() => redirectUrl()}
                            >
                                Buy Now / Activate
                            </button>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VanModalPrompt;
