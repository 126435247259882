import { dataUrl, decrypt } from "../decrypt";

async function getData() {
    try {
        const fetchData = await fetch(dataUrl);
        const response = fetchData.text();
        return response;
    } catch (error) {
        console.log(error);
    }
}

async function decryptData() {
    const toDecrypt = await getData();
    return JSON.parse(decrypt(toDecrypt));
}

export { decryptData };
