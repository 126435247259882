const numberUtil = {
    formatToPHP: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
    }),
    // to use
    // let number = 50;
    // console.log(numberUtil.formatToPHP.format(number));
    formatNumber(number) {
        try {
            return number.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        } catch (error) {
            return "error";
        }
    },
};

export { numberUtil };
