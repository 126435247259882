import { useEffect, useState } from "react";
import { decryptPolicyFlagRenewResponse } from "services/policy-flag-renew-save";
import { useNotifContext } from "store/NotifContext";
import logger from "utils/logger";

const useSetAutoRenewal = (passedPayloadFromComponent) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { updateErrorData } = useNotifContext();

    async function policyFlagRenew(payload) {
        await decryptPolicyFlagRenewResponse(payload)
            .then((data) => {
                if (data?.length) {
                    if (data[0].status === "SUCCESS") {
                        logger.log("useSetAutoRenewal", "policyFlagRenew success", data);
                        localStorage.removeItem("currAutoRenewal");
                        // localStorage.setItem("lastUpdate", data[1]?.data?.autoRenewalLastUpdate);
                        setData(data);
                        setIsPending(false);
                    } else {
                        setIsPending(false);
                        logger.log("useSetAutoRenewal", "Error", data);
                        throw new Error(data[0]?.status || "Something went wrong");
                    }
                } else {
                    throw new Error("Something went wrong.");
                }
            })
            .catch((error) => {
                logger.log("useSetAutoRenewal", "policyFlagRenew error", error);
                if (error.message === "TIMEOUT REACHED") {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Application Timeout.",
                        redirectUrl: null,
                    });
                } else {
                    updateErrorData({
                        showModal: true,
                        modalType: "error",
                        modalMessage: "Something went wrong.",
                        redirectUrl: null,
                    });
                }
                setIsPending(false);
            });
    }

    useEffect(() => {
        if (passedPayloadFromComponent !== null) {
            policyFlagRenew(passedPayloadFromComponent);
            setIsPending(true);
        } else {
            setIsPending(false);
        }
    }, [passedPayloadFromComponent]);

    return { data, isPending };
};

export default useSetAutoRenewal;
