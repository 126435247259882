import { useState } from "react";

const Input = ({ label, value, placeholder, onChange, isValid, validationMessage, maxlength, hidden }) => {
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleFocus = () => {
        setIsInputFocused(true);
    };

    const handleBlur = () => {
        setIsInputFocused(false);
    };

    if (hidden) {
        return null; // Render nothing if hidden is true
    }

    return (
        <>
            <div className="px-5 mb-5">
                <div>
                    <p className={`text-md opacity-90 ${isInputFocused ? "text-blue-600" : "text-blue-950"}`}>{label}</p>
                    <input
                        type="text"
                        placeholder={placeholder ?? label}
                        maxLength={maxlength}
                        className="w-full border-x-0 border-t-0 border-gray-300 focus:border-blue-600 text-md pl-0 py-2"
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        style={{'borderBottom': !isValid ? '1px solid red' : ''}}
                    />
                    {!isValid && <p className="text-xs text-red-500 italic">{validationMessage}</p>}
                </div>
            </div>
        </>
    );
};

export default Input;
