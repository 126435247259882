import { useEffect, useState } from "react";
import { getPlanData } from "services/decrypt";
import { useNotifContext } from "store/NotifContext";
import { useDataContext } from "store/DataContext";

const useFetchKycDropDowns = () => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const { updateErrorData } = useNotifContext();
    const { customerId } = useDataContext();

    async function fetchData() {
        const customerIdParams = customerId !== null ? customerId : localStorage.getItem("customerId");
        try {
            const kycDropDowns = await getPlanData();
            setData(kycDropDowns);
            setIsPending(false);
        } catch (error) {
            setIsPending(true);
            updateErrorData({
                showModal: true,
                modalType: "error",
                modalMessage: "Something went wrong.",
                redirectUrl: "/intro/?customerId=" + customerIdParams,
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return { data, isPending };
};

export default useFetchKycDropDowns;
